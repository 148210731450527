import Dropdown from 'common/LisaControls/Dropdown'
import { FC } from 'react'
import {
  AircraftSubtype,
  AircraftType,
  AircraftVariant,
  AssetStatus,
  EngineManufacturer,
  EngineManufacturerType,
  EngineManufacturerTypeVariant,
  EngineType,
  FamilyType,
  LandingGear,
  Manufacturer,
  SubassetPosition,
  TransitionComponent,
  TransitionOwnershipType,
  TransitionType
} from 'types'

type TransitionComponentSelectProps = {
  value: TransitionComponent | 0,
  onChange: (to: TransitionComponent) => void,
  error?: string
}
export const TransitionComponentSelect: FC<TransitionComponentSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      required
      label={'Asset Item'}
      name='transitionObject'
      showSelectOption={false}
      items={[
        { value: 0, text: 'Select' },
        { value: TransitionComponent.Aircraft, text: 'Aircraft' },
        { value: TransitionComponent.Helicopter, text: 'Helicopter' },
        { value: TransitionComponent.Engine, text: 'Engine' },
        { value: TransitionComponent.LandingGear, text: 'Landing Gear' }
      ]}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value as TransitionComponent)}/>
  )
}

// Manufacturer
type ManufacturerSelectProps = {
  value: Manufacturer
  onChange: (manufacturer: Manufacturer, manufacturerName: string) => void
  error?: string
}
export const manufacturers = [
  { value: Manufacturer.Airbus, text: 'Airbus' },
  { value: Manufacturer.Boeing, text: 'Boeing' },
  { value: Manufacturer.Embraer, text: 'Embraer' },
  { value: Manufacturer.BombardierAerospace, text: 'Bombardier' },
  { value: Manufacturer.ATR, text: 'ATR' },
  { value: Manufacturer.DeHavilland, text: 'De Havilland' },
  // { value: Manufacturer.Comac, text: 'Comac' },
  // { value: Manufacturer.DeHavilandCanada, text: 'De Haviland Canada' },
  // { value: Manufacturer.AvionTransportDeRegional, text: 'Avion Transport de Regional' },
  { value: Manufacturer.Other, text: 'Other' }
]
export const ManufacturerSelect: FC<ManufacturerSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      label={'Manufacturer'}
      name='manufacturer'
      showSelectOption={false}
      items={manufacturers}
      value={value}
      onChange={(e) => {
        const manufacturer = e.target.value as Manufacturer
        onChange(manufacturer, manufacturers.find(_ => _.value === manufacturer)?.text ?? '')
      }}/>
  )
}

type ModelSelectProps = {
  manufacturer: Manufacturer
  value: AircraftType,
  onChange: (model: AircraftType) => void
  error?: string
}

export const aircraftTypeItems = (manufacturer?: Manufacturer) => {
  const items = []
  for (const model in AircraftType) {
    if (isNaN(+model)) {
      if (manufacturer === Manufacturer.Airbus && !/A[0-9]+/g.test(model)) {
        continue
      }

      if (manufacturer === Manufacturer.Boeing && model[0] !== '7') {
        continue
      }

      if (manufacturer === Manufacturer.Embraer && model.slice(0, 5) !== 'E-Jet' && model !== 'Lineage') {
        continue
      }

      if (manufacturer === Manufacturer.BombardierAerospace && model.slice(0, 3) !== 'CRJ') {
        continue // if BombardierAerospace use only CRJ*** AND Q***
      }

      if (manufacturer === Manufacturer.ATR && model.slice(0, 3) !== 'ATR') {
        continue // if BombardierAerospace use only CRJ*** AND Q***
      }
      if (manufacturer === Manufacturer.DeHavilland && model.slice(0, 3) !== 'DHC') {
        continue // if DeHavilandCanada use only DHC***
      }
      // if (manufacturer === Manufacturer.AvionTransportDeRegional && model.slice(0, 3) !== 'ATR') {
      //   continue // if AvionTransportDeRegional use only ATR***
      // }

      items.push({
        value: Number(AircraftType[model]) as AircraftType,
        text: model
      })
    }
  }
  return items.sort()
}

export const ModelSelect: FC<ModelSelectProps> = ({ manufacturer, value, onChange }) => {
  const aircraftItems = aircraftTypeItems(manufacturer)

  return (
    <Dropdown
      label={'Model'}
      showSelectOption={false}
      name='aircraftType'
      items={aircraftItems}
      value={value}
      onChange={(e) => onChange(e.target.value as AircraftType)}/>
  )
}

type AircraftSubtypeSelectProps = {
  aircraftType: AircraftType
  value: AircraftSubtype,
  onChange: (model: AircraftSubtype) => void
  error?: string
}

export const aircraftSubtypeItems = (aircraftType?: AircraftType) => {
  const items = []
  for (const model in AircraftSubtype) {
    if (isNaN(+model)) {
      if (aircraftType === AircraftType.A220 && model.slice(0, 4) !== 'A220') {
        continue
      }

      if (aircraftType === AircraftType['A320-Family'] && model !== 'A318' && model !== 'A319N' && model !== 'A319' && model !== 'A320' && model !== 'A320N' && model !== 'A321' && model !== 'A321N') {
        continue
      }

      if (aircraftType === AircraftType['A330-Family'] && model !== 'A330-200' && model !== 'A330-300') {
        continue
      }

      if (aircraftType === AircraftType['A340-Family'] && model.slice(0, 4) !== 'A340') {
        continue
      }

      if (aircraftType === AircraftType['A350-Family'] && model.slice(0, 4) !== 'A350') {
        continue
      }

      if (aircraftType === AircraftType['A380-Family'] && model.slice(0, 4) !== 'A380') {
        continue
      }

      if (aircraftType === AircraftType['737-Family'] && model !== '737-100' && model !== '737-200' && model !== '737-300' &&
        model !== '737-400' && model !== '737-500') {
        continue
      }

      if (aircraftType === AircraftType['737-Next-Generation-(NG)'] && model !== '737-600' && model !== '737-700' && model !== '737-800' &&
        model !== '737-900') {
        continue
      }

      if (aircraftType === AircraftType['737-MAX'] && model.slice(0, 7) !== '737-MAX') {
        continue
      }

      if (aircraftType === AircraftType['747-Family'] && model.slice(0, 3) !== '747') {
        continue
      }

      if (aircraftType === AircraftType['757-Family'] && model.slice(0, 3) !== '757') {
        continue
      }

      if (aircraftType === AircraftType['767-Family'] && model.slice(0, 3) !== '767') {
        continue
      }

      if (aircraftType === AircraftType['777-Family'] && model !== '777-200' && model !== '777-300') {
        continue
      }

      if (aircraftType === AircraftType['777X-Family'] && model !== '777-8' && model !== '777-9') {
        continue
      }

      if (aircraftType === AircraftType['787-Dreamliner-Family'] && model.slice(0, 3) !== '787') {
        continue
      }

      if (aircraftType === AircraftType['E-Jet-E1-Family'] && model !== 'E170' && model !== 'E175' && model !== 'E190' && model !== 'E195') {
        continue
      }

      if (aircraftType === AircraftType['E-Jet-E2-Family'] && model !== 'E175-E2' && model !== 'E190-E2' && model !== 'E195-E2') {
        continue
      }

      if (aircraftType === AircraftType.Lineage && model !== 'Lineage 1000') {
        continue
      }

      if (aircraftType === AircraftType['CRJ-Family'] && model.slice(0, 3) !== 'CRJ') {
        continue
      }

      if (aircraftType === AircraftType['ATR-42-Family'] && model.slice(0, 3) !== 'ATR') {
        continue
      }

      if (aircraftType === AircraftType['DHC-8'] && model.slice(0, 6) !== 'Series') {
        continue
      }

      items.push({
        value: Number(AircraftSubtype[model]) as AircraftSubtype,
        text: model
      })
    }
  }

  return items.sort()
}

export const AircraftSubtypeSelect: FC<AircraftSubtypeSelectProps> = ({ aircraftType, value, onChange, error }) => {
  const items = aircraftSubtypeItems(aircraftType)

  return (
    <Dropdown
      label={'Sub-type'}
      showSelectOption={false}
      name='aircraftSubtype'
      items={items}
      value={value}
      onChange={(e) => onChange(e.target.value as AircraftSubtype)}/>
  )
}

type AircraftVariantSelectProps = {
  aircraftSubtype: AircraftSubtype
  value: AircraftVariant,
  onChange: (model: AircraftVariant) => void
  error?: string
}

export const aircraftVariantItems = (aircraftSubtype?: AircraftSubtype) => {
  const items = []
  for (const model in AircraftVariant) {
    if (isNaN(+model)) {
      if (aircraftSubtype === AircraftSubtype['A220-100'] && model !== 'A220-100') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A220-300'] && model !== 'A220-300') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A318 && model.slice(0, 4) !== 'A318') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A319 && model !== 'A319-100' && model !== 'A319-111' && model !== 'A319-112' && model !== 'A319-131') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A319N && model !== 'A319-171N' && model !== 'A319-151N' && model !== 'A319-153N') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A320 && model !== 'A320-100' && model !== 'A320-200' && model !== 'A320-211' && model !== 'A320-214' && model !== 'A320-232') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A320N && model !== 'A320-271N' && model !== 'A320-272N' && model !== 'A320-273N' && model !== 'A320-251N' && model !== 'A320-252N' && model !== 'A320-253N') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A321 && model !== 'A321-100' && model !== 'A321-200' && model !== 'A321-211') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.A321N && model !== 'A321-271N' && model !== 'A321-272N' && model !== 'A321-251N' && model !== 'A321-252N' && model !== 'A321-253N') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A330-200'] && model !== 'A330-223' && model !== 'A330-243') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A330-300'] && model !== 'A330-323' && model !== 'A330-343') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A340-200'] && model !== 'A340-211' && model !== 'A340-212') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A340-300'] && model !== 'A340-313') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A340-500'] && model !== 'A340-541') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A340-600'] && model !== 'A340-642') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A350-900'] && model !== 'A350-941') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A350-1000'] && model !== 'A350-1041') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['A380-800'] && model !== 'A380-841' && model !== 'A380-842' && model !== 'A380-861') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-100'] && model !== '737-100') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-200'] && model !== '737-222' && model !== '737-232') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-300'] && model !== '737-300') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-400'] && model !== '737-400') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-500'] && model !== '737-500') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-600'] && model !== '737-600') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-700'] && model !== '737-7H4' && model !== '737-7K2') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-800'] && model !== '737-8H4' && model !== '737-8K2') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737-900'] && model !== '737-924' && model !== '737-9F6') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737 MAX 7'] && model !== '737 MAX 7') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737 MAX 8'] && model !== '737 MAX 8') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737 MAX 9'] && model !== '737 MAX 9') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['737 MAX 10'] && model !== '737 MAX 10') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['747-100'] && model !== '747-121' && model !== '747-124') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['747-200'] && model !== '747-222' && model !== '747-283') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['747-300'] && model !== '747-312' && model !== '747-3B3') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['747-400'] && model !== '747-438' && model !== '747-4H6') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['747-8'] && model !== '747-8I (Intercontinental)' && model !== '747-8F (Freighter)') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['757-200'] && model !== '757-223' && model !== '757-236') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['757-300'] && model !== '757-330') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['767-200'] && model !== '767-222' && model !== '767-231') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['767-300'] && model !== '767-323' && model !== '767-336') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['767-400'] && model !== '767-424ER') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['777-200'] && model !== '777-200ER' && model !== '777-200LR') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['777-300'] && model !== '777-300ER') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['777-8'] && model !== '777-8') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['777-9'] && model !== '777-9') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['787-8'] && model !== '787-8' && model !== '787-8K') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['787-9'] && model !== '787-9') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['787-10'] && model !== '787-10') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.E170 && model !== 'ERJ 170-100 LR' && model !== 'ERJ 170-100 STD') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.E190 && model !== 'ERJ 190-100 IGW' && model !== 'ERJ 190-100 LR' && model !== 'ERJ 190-100 SR' && model !== 'ERJ 190-100 STD') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.E195 && model !== 'ERJ 190-200 IGW' && model !== 'ERJ 190-200 LR' && model !== 'ERJ 190-200 STD') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['E175-E2'] && model !== 'EMB 190-500') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['E190-E2'] && model !== 'EMB 190-300') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['E195-E2'] && model !== 'EMB 190-400') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['Lineage 1000'] && model !== 'ERJ 190-100 ECJ') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.CRJ100 && model !== 'CRJ100') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.CRJ200 && model !== 'CRJ200ER' && model !== 'CRJ200LR') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.CRJ700 && model !== 'CRJ701' && model !== 'CRJ702') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.CRJ900 && model !== 'CRJ900ER' && model !== 'CRJ900LR') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype.CRJ1000 && model !== 'CRJ1000') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['ATR 42-300'] && model !== 'ATR 42-320') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['ATR 42-500'] && model !== 'ATR 42-500') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['ATR 42-600'] && model !== 'ATR 42-600') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['ATR 72-200'] && model !== 'ATR 72-200') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['ATR 72-500'] && model !== 'ATR 72-500') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['ATR 72-600'] && model !== 'ATR 72-600') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['Series 100'] && model.slice(0, 7) !== 'DHC-8-1') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['Series 200'] && model.slice(0, 7) !== 'DHC-8-2') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['Series 300'] && model.slice(0, 7) !== 'DHC-8-3') {
        continue
      }

      if (aircraftSubtype === AircraftSubtype['Series 400'] && model.slice(0, 7) !== 'DHC-8-4') {
        continue
      }

      items.push({
        value: Number(AircraftVariant[model]) as AircraftVariant,
        text: model
      })
    }
  }

  return items.sort()
}

export const AircraftVariantSelect: FC<AircraftVariantSelectProps> = ({ aircraftSubtype, value, onChange, error }) => {
  const items = aircraftVariantItems(aircraftSubtype)

  return (
    <Dropdown
      label={'Variant'}
      showSelectOption={false}
      name='variation'
      items={items}
      value={value}
      onChange={(e) => onChange(e.target.value as AircraftVariant)}/>
  )
}

type FamilyTypeProps = {
  disabled?: boolean
  value: FamilyType | ''
  onChange: (familyType: FamilyType) => void
}
export const FamilyTypeSelect: FC<FamilyTypeProps> = ({ value, onChange, disabled }) => {
  return (
    <Dropdown
      disabled={disabled}
      label={'Family'}
      name='familyType'
      showSelectOption={false}
      items={[
        { value: FamilyType.WB, text: 'WB' },
        { value: FamilyType.NB, text: 'NB' },
        { value: FamilyType.RJ, text: 'RJ' }
      ]}
      value={value}
      onChange={(e) => onChange(e.target.value as FamilyType)}/>
  )
}

type TypeSelectProps = {
  value: TransitionType | 0
  onChange: (type: TransitionType) => void
  error?: string
}
export const TypeSelect: FC<TypeSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      required
      label={'Asset Type'}
      name='transitionType'
      items={[
        { value: TransitionType.AssetManagement, text: 'Asset Management' },
        { value: TransitionType.PhaseIn, text: 'Aircraft Transition: Phase-In' },
        { value: TransitionType.PhaseOut, text: 'Aircraft Transition: Phase-Out' },
        { value: TransitionType.LessorAircraft, text: 'Lessor Aircraft (Re-)Delivery' },
        { value: TransitionType.PrePurchaseInspection, text: 'Pre-purchase inspection' },
        { value: TransitionType.PreliminaryInspection, text: 'Preliminary Inspection' },
        { value: TransitionType.ArcReview, text: 'Airworthiness Review' },
        { value: TransitionType.UnassignedDocuments, text: 'AMOS Unassigned documenets' }
      ].sort((a, b) => a.text.localeCompare(b.text))}
      showSelectOption={false}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value as TransitionType)}/>
  )
}

type EnginesSelectProps = {
  disabled?: boolean
  value: number
  onChange: (engineNumber: number) => void
  max?: number
}
export const EngineNumberSelect: FC<EnginesSelectProps> = ({ value, onChange, disabled = false, max }) => {
  return (
    <Dropdown
      disabled={disabled}
      label={'Engines'}
      name='engineAmount'
      showSelectOption={false}
      items={[
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' }
      ].filter(({ value }) => max && value <= max)}
      value={value}
      onChange={(e) => onChange(e.target.value as number)}/>
  )
}

export const landingGearOptions = [
  { value: LandingGear.NLG, text: 'NLG', property: 'nlg' },
  { value: LandingGear.LHMLG, text: 'LH MLG', property: 'lhMlg' },
  { value: LandingGear.RHMLG, text: 'RH MLG', property: 'rhMlg' },
  { value: LandingGear.LHCLG, text: 'LH CLG', property: 'lhClg' },
  { value: LandingGear.RHCLG, text: 'RH CLG', property: 'rhClg' },
  { value: LandingGear.CLDG, text: 'C LDG', property: 'cLdh' }
]
type LandingGearSelectProps = {
  disabled?: boolean
  value: string[]
  onChange: (landingGears: string[]) => void
  error?: string
}
export const LandingGearSelect: FC<LandingGearSelectProps> = ({ value, onChange, error, disabled = false }) => {
  return (
    <Dropdown
      disabled={disabled}
      required
      multiple
      label={'Landing Gears'}
      name='engineAmount'
      items={landingGearOptions}
      value={value}
      error={error}
      onChange={(e) => onChange(e.target.value as string[])}/>
  )
}

export const engineTypeOptions = [
  { value: EngineType.Jet, text: 'Jet' },
  { value: EngineType.Turboprop, text: 'Turboprop' }
]
type EngineTypeSelectProps = {
  disabled?: boolean
  value: EngineType | ''
  onChange: (engineType: EngineType) => void
  error?: string
}
export const EngineTypeSelect: FC<EngineTypeSelectProps> = ({ value, onChange, error, disabled = false }) => {
  return (
    <Dropdown
      disabled={disabled}
      required
      label={'Type'}
      name='engineType'
      items={engineTypeOptions}
      value={value}
      error={error}
      onChange={(e) => {
        const et = e.target.value as EngineType
        if (et) {
          onChange(et)
        }
      }}/>
  )
}

// Engine Manufacturer
type EngineManufacturerSelectProps = {
  value: EngineManufacturer
  onChange: (manufacturer: EngineManufacturer) => void
  error?: string
}

export const engineManufacturers = [
  { value: EngineManufacturer.GeneralElectric, text: 'GE' },
  { value: EngineManufacturer.RollsRoyce, text: 'RR' },
  { value: EngineManufacturer.InternationalAeroEngines, text: 'IAE' },
  { value: EngineManufacturer.CFMInternational, text: 'CFM' },
  { value: EngineManufacturer.EngineAlliance, text: 'EA' },
  { value: EngineManufacturer.PrattWhittney, text: 'PW' }
]

export const EngineManufacturerSelect: FC<EngineManufacturerSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      label={'Manufacturer'}
      name='engineManufacturer'
      showSelectOption={false}
      items={engineManufacturers}
      value={value}
      onChange={(e) => {
        const manufacturer = e.target.value as EngineManufacturer
        onChange(manufacturer)
      }}/>
  )
}

// Engine Manufacturer Type
type EngineManufacturerTypeSelectProps = {
  manufacturer: EngineManufacturer
  value: EngineManufacturerType
  onChange: (engineManufacturerType: EngineManufacturerType) => void
  error?: string
}

export const engineManufacturerTypes = (engineManufacturer?: EngineManufacturer) => {
  const items = []
  for (const model in EngineManufacturerType) {
    if (engineManufacturer === EngineManufacturer.GeneralElectric && model.slice(0, 2) !== 'GE' && model !== 'CF6' && model !== 'CF34') {
      continue
    }

    if (engineManufacturer === EngineManufacturer.RollsRoyce && model.slice(0, 5) !== 'Trent' && model !== 'RB211') {
      continue
    }

    if (engineManufacturer === EngineManufacturer.InternationalAeroEngines && model !== 'V2500') {
      continue
    }

    if (engineManufacturer === EngineManufacturer.CFMInternational && model.slice(0, 5) !== 'CFM56' && model !== 'LEAP') {
      continue
    }

    if (engineManufacturer === EngineManufacturer.EngineAlliance && model !== 'GP7000') {
      continue
    }

    if (engineManufacturer === EngineManufacturer.PrattWhittney && model !== 'GTF' && model.slice(0, 2) !== 'PW' && model.slice(0, 2) !== 'JT') {
      continue // if AvionTransportDeRegional use only ATR***
    }

    items.push({
      value: Number(EngineManufacturerType[model]) as EngineManufacturerType,
      text: model
    })
  }

  return items.sort()
}

export const EngineManufacturerTypeSelect: FC<EngineManufacturerTypeSelectProps> = ({ manufacturer, value, onChange, error }) => {
  const engManTypes = engineManufacturerTypes(manufacturer)

  return (
    <Dropdown
      label={'Engine Type'}
      name='engineManufacturerType'
      showSelectOption={false}
      items={engManTypes}
      value={value}
      onChange={(e) => onChange(e.target.value as EngineManufacturerType)}/>
  )
}

// Engine Manufacturer Type Variant
type EngineManufacturerTypeVariantSelectProps = {
  manufacturerType: EngineManufacturerType
  value: EngineManufacturerTypeVariant
  onChange: (engineManufacturerTypeVariant: EngineManufacturerTypeVariant) => void
  error?: string
}

export const engineManufacturerTypeVariants = (engineManufacturerType?: EngineManufacturerType) => {
  const items = []
  for (const model in EngineManufacturerTypeVariant) {
    if (engineManufacturerType === EngineManufacturerType.GE9X && model !== '105B1A') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.GEnx && model.slice(0, 5) !== 'GEnx-') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CF6 && model.slice(0, 3) !== 'CF6' && model !== 'F138-100' && model !== 'CF-80E1') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.GE90 && model.slice(0, 5) !== 'GE90-') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CF34 && model.slice(0, 5) !== 'CF34-') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.TrentXWB && model.slice(0, 9) !== 'Trent XWB') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.Trent900 && model.slice(0, 7) !== 'Trent 9') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.Trent600 && model !== 'Trent 600') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.Trent700 && model.slice(0, 7) !== 'Trent 7') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.Trent7000 && model !== 'Trent 7000') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.Trent800 && model.slice(0, 5) !== 'RB211') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.Trent1000 && model.slice(0, 10) !== 'Trent 1000') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.RB211 &&
        model !== '-22' &&
        model !== '-524B/C/D' &&
        model !== '-524G/H' &&
        model !== '-535' &&
        model !== 'TRENT556B61-22' &&
        model !== 'Trent 768-60' &&
        model !== 'Trent 772-60' &&
        model !== 'Trent 772B-60' &&
        model !== 'Trent 772C-60' &&
        model !== '-22B' &&
        model !== '-22C' &&
        model !== '-24' &&
        model !== '-52' &&
        model !== '-56' &&
        model !== '-61' &&
        model !== '-524' &&
        model !== '-524B' &&
        model !== '-524C' &&
        model !== '-524D' &&
        model !== '-524G' &&
        model !== '-524G-T' &&
        model !== '-524H' &&
        model !== '-524H-T' &&
        model !== '-535C' &&
        model !== '-535E' &&
        model !== '-535E4' &&
        model !== 'WR-21'
        ) {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.V2500 && model !== '-A1' && model !== '-A5' && model !== '-D5' && model !== '-E5' && model !== 'V2500SelectOne' && model !== 'V2500SelectTwo') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CFM565A && model.slice(0, 3) !== '-5A') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CFM565B && model.slice(0, 3) !== '-5B') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CFM565C && model.slice(0, 3) !== '-5C') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CFM567B && model.slice(0, 3) !== '-7B') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CFM563B && model.slice(0, 3) !== '-3B') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.CFM563C && model.slice(0, 3) !== '-3C') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.LEAP && model !== '-1A' && model !== '-1B' && model !== '-1C') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.GP7000 && model !== 'GP7270') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.GTF && model !== 'PW1100G' && model !== 'PW1400G' && model !== 'PW1500G' && model !== 'PW1900G' && model !== 'PW102XG' && model !== 'PW1700G' && model !== 'PW1200G') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW800 && model.slice(0, 3) !== 'PW8') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW300 && model.slice(0, 3) !== 'PW3') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW4000 && model.slice(0, 6) !== 'PW4000') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW2000 && model !== 'PW2000' && model !== 'F117-PW-100') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW6000 && model !== 'PW6000') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW500 && model.slice(0, 3) !== 'PW5') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.JT8D && model !== '--1(A/B)/7(A/B)/9(A)/11' && model !== '-5' && model !== '-15(A)' && model !== '-17(A/R/AR)' && model !== '-209' && model !== '-217(A/C)' && model !== '-219') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.JT9D && model.slice(0, 4) !== 'JT9D') {
      continue
    }

    if (engineManufacturerType === EngineManufacturerType.PW100 && model !== 'PW150A' && model !== 'PW127A' && model !== 'PW120/PW121' &&
      model !== 'PW127E/XT-M/XT-L' && model !== 'PW124B' && model !== 'PW127F/XT-M' && model !== 'PW126' &&
      model !== 'PW123AF' && model !== 'PW123' && model !== 'PW150' && model !== 'PW119' && model !== 'PW127G' &&
      model !== 'PW118/118A/118B' && model !== 'PW125B/127B' && model !== 'PW127H' && model !== 'PW127J' && model !== 'PW127C') {
      continue
    }

    items.push({
      value: Number(EngineManufacturerTypeVariant[model]) as EngineManufacturerTypeVariant,
      text: model
    })
  }

  return items.sort()
}

export const EngineManufacturerTypeVariantSelect: FC<EngineManufacturerTypeVariantSelectProps> = ({ manufacturerType, value, onChange, error }) => {
  const engManTypeVars = engineManufacturerTypeVariants(manufacturerType)

  return (
    <Dropdown
      label={'Variant'}
      name='engineManufacturerTypeVariant'
      showSelectOption={false}
      items={engManTypeVars}
      value={value}
      onChange={(e) => onChange(e.target.value as EngineManufacturerTypeVariant)}/>
  )
}

export const aircraftStatuses = () => {
  const items = []
  for (const model in AssetStatus) {
    if (model !== 'TransitionPhaseIn' && model !== 'Operation' && model !== 'Parking' &&
          model !== 'Storage' && model !== 'TransitionPhaseOut' && model !== 'TransitionParking' && model !== 'TransitionStorage'
    ) {
      continue
    }

    items.push({
      value: Number(AssetStatus[model]) as AssetStatus,
      text: model
    })
  }

  return items.sort()
}

export const assetStatuses = () => {
  const items = []
  for (const model in AssetStatus) {
    if (model !== 'OnWing' && model !== 'Stored' && model !== 'InTransition' && model !== 'ShopVisit' &&
          model !== 'InLogistics'
    ) {
      continue
    }

    items.push({
      value: Number(AssetStatus[model]) as AssetStatus,
      text: model
    })
  }

  return items.sort()
}

// Asset Status
type AssetStatusSelectProps = {
  value: AssetStatus
  onChange: (status: AssetStatus) => void
  error?: string
}

export const AssetStatusSelect: FC<AssetStatusSelectProps> = ({ value, onChange, error }) => {
  const items = assetStatuses()

  return (
    <Dropdown
      label={'Status'}
      name='status'
      showSelectOption={false}
      items={items}
      value={value}
      onChange={(e) => {
        const status = e.target.value as AssetStatus
        onChange(status)
      }}/>
  )
}

export const AircraftStatusSelect: FC<AssetStatusSelectProps> = ({ value, onChange, error }) => {
  const items = aircraftStatuses()

  return (
    <Dropdown
      label={'Status'}
      name='status'
      showSelectOption={false}
      items={items}
      value={value}
      onChange={(e) => {
        const status = e.target.value as AssetStatus
        onChange(status)
      }}/>
  )
}

export const enginePositions = () => {
  const items = []
  for (const model in SubassetPosition) {
    if (model !== 'E1' && model !== 'E2' && model !== 'E3' && model !== 'E4') {
      continue
    }

    items.push({
      value: Number(SubassetPosition[model]) as SubassetPosition,
      text: model
    })
  }

  return items.sort()
}

type EnginePositionsSelectProps = {
  value: SubassetPosition
  onChange: (status: SubassetPosition) => void
  error?: string
}

export const EnginePositionsSelect: FC<EnginePositionsSelectProps> = ({ value, onChange, error }) => {
  const items = enginePositions()

  return (
    <Dropdown
      label={'Position'}
      name='position'
      showSelectOption={false}
      items={items}
      value={value}
      onChange={(e) => onChange(e.target.value as SubassetPosition)}/>
  )
}

export const landingGearPositions = () => {
  const items = []
  for (const model in SubassetPosition) {
    if (model !== 'NLG' && model !== 'RH' && model !== 'LH' && model !== 'CLDG' &&
        model !== 'LHCLG' && model !== 'RHCLG' && model !== 'RHMLG' && model !== 'LHMLG') {
      continue
    }

    items.push({
      value: Number(SubassetPosition[model]) as SubassetPosition,
      text: model
    })
  }

  return items.sort()
}

type LandingGearPositionsSelectProps = {
  value: SubassetPosition
  onChange: (status: SubassetPosition) => void
  error?: string
}

export const LandingGearPositionsSelect: FC<LandingGearPositionsSelectProps> = ({ value, onChange, error }) => {
  const items = landingGearPositions()

  return (
    <Dropdown
      label={'LDG Type'}
      name='position'
      showSelectOption={false}
      items={items}
      value={value}
      onChange={(e) => onChange(e.target.value as SubassetPosition)}/>
  )
}

export const ownershipTypes = [
  { value: TransitionOwnershipType.Leased, text: 'Leased' },
  { value: TransitionOwnershipType.Owned, text: 'Owned' }
]

type OwnershipTypesProps = {
  value: TransitionOwnershipType
  onChange: (type: TransitionOwnershipType) => void
  error?: string
}

export const OwnershipTypesSelect: FC<OwnershipTypesProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      label={'Ownership type'}
      name='ownershipType'
      items={ownershipTypes}
      value={value}
      showSelectOption={false}
      error={error}
      onChange={(e) => {
        const et = e.target.value as TransitionOwnershipType
        if (et) {
          onChange(et)
        }
      }}/>
  )
}
