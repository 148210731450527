import {
  AircraftType,
  EngineType,
  FamilyType,
  LandingGear,
  Manufacturer,
  Nullable,
  ProjectId,
  SortDirection,
  Transition,
  TransitionComponent,
  TransitionFieldType,
  TransitionId,
  TransitionItem,
  User,
  UserId
} from 'types'

export const wantsTransitionField = (
  transitionComponent: TransitionComponent,
  transitionField: TransitionFieldType
): boolean => {
  if ([TransitionComponent.Aircraft, TransitionComponent.Helicopter].includes(transitionComponent)) {
    return true
  }
  if (transitionComponent === TransitionComponent.Engine) {
    return !['landingGears', 'landingGearPNs', 'landingGearSNs', 'engineAmount', 'apuPn', 'apuSn'].includes(transitionField)
  }
  if (transitionComponent === TransitionComponent.LandingGear) {
    return !['engineAmount', 'engineESN1', 'engineESN2', 'engineESN3', 'engineESN4'].includes(transitionField)
  }
  return true
}

const NB_AIRCRAFT_TYPES = [
  AircraftType['A320-Family'],
  AircraftType['737-Family'], AircraftType['737-MAX'], AircraftType['737-Next-Generation-(NG)'],
  AircraftType['757-Family']
]

const WB_AIRCRAFT_TYPES = [
  AircraftType['A330-Family'], AircraftType['A340-Family'], AircraftType['A350-Family'], AircraftType['A380-Family'],
  AircraftType['767-Family'], AircraftType['777-Family'], AircraftType['777X-Family'], AircraftType['787-Dreamliner-Family'],
  AircraftType['747-Family']
]

const RJ_AIRCRAFT_TYPES = [
  AircraftType['E-Jet-E1-Family'], AircraftType['E-Jet-E2-Family'],
  AircraftType['CRJ-Family'],
  AircraftType['ATR-42-Family'], AircraftType['ATR-72-Family']
]

const TWO_ENGINE_AIRCRAFT_TYPES = [
  AircraftType.A220, AircraftType['A320-Family'], AircraftType['A330-Family'],
  AircraftType['A350-Family'], AircraftType['737-Family'], AircraftType['737-Next-Generation-(NG)'], AircraftType['737-MAX'],
  AircraftType['757-Family'], AircraftType['767-Family'], AircraftType['777-Family'], AircraftType['777X-Family'],
  AircraftType['787-Dreamliner-Family'], AircraftType['E-Jet-E1-Family'], AircraftType['E-Jet-E2-Family'],
  AircraftType['CRJ-Family'], AircraftType['ATR-42-Family'], AircraftType['ATR-72-Family']
]

const FOUR_ENGINE_AIRCRAFT_TYPES = [
  AircraftType['A340-Family'], AircraftType['A380-Family'], AircraftType['747-Family']
]

const PROPELLER_ENGINE_TYPES = [AircraftType['ATR-42-Family'], AircraftType['ATR-72-Family']]

const getLendingGearsForTransition = (transition: Partial<Transition>): Nullable<LandingGear[]> => {
  switch (Number(transition.manufacturer)) {
  case Manufacturer.Airbus:
    switch (Number(transition.aircraftType)) {
    case AircraftType['A380-Family']:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG, LandingGear.RHCLG, LandingGear.LHCLG]
    case AircraftType['A340-Family']:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG, LandingGear.CLDG]
    default:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG]
    }
  case Manufacturer.Boeing:
    switch (Number(transition.aircraftType)) {
    case AircraftType['747-Family']:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG, LandingGear.RHCLG, LandingGear.LHCLG]
    default:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG]
    }
  case Manufacturer.Embraer:
  case Manufacturer.BombardierAerospace:
    return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG]
  default:
    return null
  }

  // case Manufacturer.AvionTransportDeRegional:
  // case Manufacturer.DeHavilandCanada:
}

export const applyManufacturerParams = (transition: Partial<Transition>): Partial<Transition> => {
  const aircraftType: AircraftType = Number(transition.aircraftType)
  const t = { ...transition }
  // apply family
  if (NB_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.familyType = FamilyType.NB
  }
  if (WB_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.familyType = FamilyType.WB
  }
  if (RJ_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.familyType = FamilyType.RJ
  }
  // Apply engine numbers
  if (TWO_ENGINE_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.engineAmount = 2
  }
  if (FOUR_ENGINE_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.engineAmount = 4
  }
  t.engineType = PROPELLER_ENGINE_TYPES.includes(aircraftType) ? EngineType.Turboprop : EngineType.Jet
  // Apply landing gear for transition. Ignore if not applicable
  const landingGears = getLendingGearsForTransition(t)
  if (landingGears !== null) {
    t.landingGears = landingGears
  }
  return t
}

export const sortTransitionsByCreateDate = (transitions: TransitionItem[], direction: SortDirection): TransitionItem[] => {
  return [...transitions].sort((a, z) => {
    return a.createdDate.localeCompare(z.createdDate) * (direction === 'asc' ? -1 : 1)
  })
}

export const filterTransitionsByProjectId = <T extends { projectId: ProjectId }> (transitions: T[], projectId: ProjectId): T[] => {
  return transitions.filter(_ => projectId === '' || _.projectId === projectId)
}

export const filterTransitionsByName = <T extends { transitionName: string, projectName: string }> (
  transitions: T[], name: string): T[] => {
  return transitions.filter(_ => _.transitionName.toUpperCase().includes(name.toUpperCase()) ||
    _.projectName.toUpperCase().includes(name.toUpperCase()))
}

export const filterTransitionsByUser = <T extends { users: User[] }> (transitions: T[], userId: UserId) =>
  transitions.filter((t) => !userId || t.users.some((u) => u.userId === userId))

export const deliveryBibleStorageKey = (transitionId: string) => `deliveryName_${transitionId}`

export const setDeliveryBibleName = (transitionId: TransitionId, deliveryBibleName: string) => {
  localStorage.setItem(deliveryBibleStorageKey(transitionId), deliveryBibleName)
}

export const getDeliveryBibleName = (transitionId: TransitionId) =>
  localStorage.getItem(deliveryBibleStorageKey(transitionId))

export const unsetDeliveryBibleName = (transitionId: TransitionId) =>
  localStorage.removeItem(deliveryBibleStorageKey(transitionId))
