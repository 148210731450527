import { createContext, FC, MouseEvent, useContext, useEffect, useMemo } from 'react'
import { DocumentId, TransitionId, User, UserId, TransitionComponent } from 'types'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentTransitionId } from 'redux/slices/transitionSlice'
import { resetAll as resetA2KLabels } from 'redux/slices/a2kLabelsSlice'
import { resetAll as resetQueries } from 'redux/slices/queriesSlice'
import { resetAll as resetImportDocuments } from 'redux/slices/importDocumentsSlice'
import { reset as resetClientLabels } from 'redux/slices/clientLabelsSlice'
import { setHierarchy as setHierarchyForQuery } from 'redux/slices/queryLabelsSlice'
import { setHierarchy as setHierarchyForBulkAssign } from 'redux/slices/bulkAssignLabelsSlice'
import { setLabels as setLabelsForDocumentsSearch } from 'redux/slices/searchDocumentsSlice'
import { setHierarchy as setLabels } from 'redux/slices/labelsSlice'
import { isErrorCode } from 'utils/http'
import { useGetLabelsQuery } from 'services/api/labellingApi'
import { useGetTransitionQuery, useGetTransitionTeamQuery } from 'services/api/transitionApi'

export interface Transition {
  transitionId: TransitionId
  transitionComponent: TransitionComponent | undefined
  openPDFDocument: (e: MouseEvent<HTMLElement>, documentId: DocumentId, options?: DocumentLinkLinkOptions) => void
  openDocument: (documentId: DocumentId) => void
  team: Map<UserId, User>
  teamList: User[]
}

export const DEFAULT_TRANSITION: Transition = {
  transitionId: '',
  transitionComponent: TransitionComponent.Aircraft,
  openPDFDocument: () => {},
  openDocument: () => {},
  team: new Map(),
  teamList: []
}

export const TransitionContext = createContext<Transition>(DEFAULT_TRANSITION)

export type DocumentLinkLinkOptions = {
  searchParam?: string
}

type TransitionProviderProps = {
  transitionId?: TransitionId
}
export const TransitionProvider: FC<TransitionProviderProps> = ({ transitionId, children }) => {
  const { id: paramsTransitionId } = useParams<{id: TransitionId}>()
  const id = transitionId ?? paramsTransitionId
  const dispatch = useDispatch()
  const history = useHistory()
  const { data: transition, isFetching } = useGetTransitionQuery(id)
  const { data: labels, fulfilledTimeStamp: lastTimeLabelsLoaded } = useGetLabelsQuery(id)
  const { data: team = [] } = useGetTransitionTeamQuery(id, { skip: !transition?.transitionId })

  const clearTransitionData = () => {
    dispatch(resetQueries())
    dispatch(resetA2KLabels())
    dispatch(resetClientLabels())
    dispatch(resetImportDocuments())
  }

  const openDocument = (documentId: DocumentId, options: DocumentLinkLinkOptions = {}) => {
    let url = `/document/${documentId}?tId=${id}`
    if (options.searchParam) {
      url = `${url}&s=${options.searchParam}`
    }
    window.open(url, '_blank')
  }

  const openPDFDocument = (
    e: MouseEvent<HTMLElement>,
    documentId: DocumentId,
    options: DocumentLinkLinkOptions = {}) => {
    e.stopPropagation()
    openDocument(documentId, options)
  }

  useEffect(() => {
    if (transition?.transitionId !== id) { // Do not clear transition data if transition is not changed
      clearTransitionData()
    }
  }, [id])

  useEffect(() => {
    if (!isFetching) {
      if (!isErrorCode(transition!.message)) {
        dispatch(setCurrentTransitionId(id))
      } else {
        history.replace(`/error/code/${transition?.message}`)
      }
    }
  }, [transition?.message, isFetching])

  useEffect(() => {
    if (lastTimeLabelsLoaded && labels) {
      dispatch(setHierarchyForQuery(labels)) // @todo deprecating; useLabels
      dispatch(setHierarchyForBulkAssign(labels)) // @todo deprecating; useLabels
      dispatch(setLabelsForDocumentsSearch(labels))
      dispatch(setLabels(labels))
    }
    if (labels === null) {
      dispatch(setLabelsForDocumentsSearch(labels))
    }
  }, [lastTimeLabelsLoaded])

  const teamMap = useMemo(() => {
    return new Map(team.map((t) => [t.userId, t]))
  }, [team])

  return (
    <TransitionContext.Provider value={{ transitionId: id, transitionComponent: transition?.transitionComponent, openPDFDocument, openDocument, team: teamMap, teamList: team }}>
      {children}
    </TransitionContext.Provider>
  )
}

export const useTransitionContext = () => useContext(TransitionContext)
export const useTransitionId = (): TransitionId => useTransitionContext().transitionId
