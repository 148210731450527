import { ChangeEvent, FC, useState, MouseEvent, useEffect } from 'react'
import { Divider, Grid, IconButton, makeStyles, Typography, TableContainer, Paper, Table, TableRow, TableCell, TableHead, TableBody, MenuItem, Menu, Button } from '@material-ui/core'
import { AircraftType, Manufacturer, ProjectId, SubassetPosition, Transition, TransitionComponent } from 'types'
import {
  AircraftStatusSelect,
  AircraftSubtypeSelect,
  AircraftVariantSelect,
  FamilyTypeSelect,
  ManufacturerSelect,
  ModelSelect,
  OwnershipTypesSelect
} from './TransitionElements'
import Input from 'common/LisaControls/Input'
import { Close as CloseIcon, Edit as EditIcon } from '@material-ui/icons'
import { openModal } from 'redux/slices/appSlice'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { AssetModal } from '../components/projects/AssetModal'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    paddingLeft: '16px',
    borderRadius: '4px 4px 0 0',
    backgroundColor: `${theme.palette.grey2.main}`
  },
  headRow: {
    '& .MuiTableCell-head': {
      fontWeight: 900
    }
  }
}))

const TWO_ENGINE_AIRCRAFT_TYPES = [
  AircraftType.A220, AircraftType['A320-Family'], AircraftType['A330-Family'],
  AircraftType['A350-Family'], AircraftType['737-Family'], AircraftType['737-Next-Generation-(NG)'], AircraftType['737-MAX'],
  AircraftType['757-Family'], AircraftType['767-Family'], AircraftType['777-Family'], AircraftType['777X-Family'],
  AircraftType['787-Dreamliner-Family'], AircraftType['E-Jet-E1-Family'], AircraftType['E-Jet-E2-Family'],
  AircraftType['CRJ-Family'], AircraftType['ATR-42-Family'], AircraftType['ATR-72-Family']
]

const FOUR_ENGINE_AIRCRAFT_TYPES = [
  AircraftType['A340-Family'], AircraftType['A380-Family'], AircraftType['747-Family']
]

type TransitionInfoFormProps = {
  projectId: ProjectId
  transition: Partial<Transition>
  errors: any // @todo
  transitionError: boolean
  transitionErrorMessage: string
  onChange: (field: string, value: any) => void
  onSubassetsChange: (subassets: Partial<Transition>[]) => void
  showSubassetTeam?: boolean
}
export const TransitionInfoForm: FC<TransitionInfoFormProps> = ({
  projectId,
  transition,
  onChange,
  errors,
  onSubassetsChange,
  showSubassetTeam = true
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [subassets, setSubassets] = useState<Partial<Transition>[]>(transition.subAssets !== undefined ? transition.subAssets : [])
  const [currSubasset, setCurrSubasset] = useState<Partial<Transition> | undefined>(undefined)
  const [currSubassetIndex, setCurrSubassetIndex] = useState<number | undefined>(undefined)
  const [assetType, setAssetType] = useState<TransitionComponent | undefined>(undefined)

  function onAssetModalClosed () {
    setAssetType(undefined)
  }

  useEffect(() => {
    onSubassetsChange(subassets)
  }, [subassets])

  function subassetAdded (subasset: Partial<Transition>) {
    subasset.projectId = projectId
    setSubassets([
      ...subassets,
      subasset
    ])
    setAssetType(undefined)
  }

  function subassetUpdated (subasset: Partial<Transition>) {
    const items = subassets.map((curr, i) => {
      if (i === currSubassetIndex) {
        return subasset
      } else {
        return curr
      }
    })

    setSubassets(items)
    setAssetType(undefined)
  }

  function removeSubasset (index: number) {
    setSubassets(transition.subAssets!.filter((_, i) => i !== index))
  }

  function editSubasset (subasset: Partial<Transition>, index: number) {
    setCurrSubasset(subasset)
    setCurrSubassetIndex(index)
    setAssetType(subasset.transitionComponent)
    dispatch(openModal('ASSET_MODAL'))
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClickSubassetsMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseSubassetsMenu = () => {
    setAnchorEl(null)
  }

  const openModalForAddingAsset = (assetType: TransitionComponent) => {
    setAssetType(assetType)
    setCurrSubasset(undefined)
    dispatch(openModal('ASSET_MODAL'))
    handleCloseSubassetsMenu()
  }

  const getDefaultSubasset = (type: TransitionComponent, position: SubassetPosition) => {
    const asset:Partial<Transition> = {
      transitionComponent: type,
      name: '',
      manufacturerName: '',
      engineManufacturer: 1,
      engineManufacturerType: 1,
      engineManufacturerTypeVariant: 1,
      engineType: 1,
      msn: '-',
      partNumber: '',
      installedPosition: position,
      status: 6,
      ownershipStatus: 1,
      owner: '',
      previousOperator: '',
      newOperator: '',
      projectId: projectId,
      isActive: true
    }

    return asset
  }

  const addDefaultSubassets = (event: MouseEvent<HTMLElement>) => {
    const defaults = []
    if (TWO_ENGINE_AIRCRAFT_TYPES.includes(transition.aircraftType!)) {
      defaults.push(getDefaultSubasset(TransitionComponent.Engine, SubassetPosition.E1))
      defaults.push(getDefaultSubasset(TransitionComponent.Engine, SubassetPosition.E2))
    } else if (FOUR_ENGINE_AIRCRAFT_TYPES.includes(transition.aircraftType!)) {
      defaults.push(getDefaultSubasset(TransitionComponent.Engine, SubassetPosition.E1))
      defaults.push(getDefaultSubasset(TransitionComponent.Engine, SubassetPosition.E2))
      defaults.push(getDefaultSubasset(TransitionComponent.Engine, SubassetPosition.E3))
      defaults.push(getDefaultSubasset(TransitionComponent.Engine, SubassetPosition.E4))
    }

    switch (Number(transition.manufacturer)) {
    case Manufacturer.Airbus:
      switch (Number(transition.aircraftType)) {
      case AircraftType['A380-Family']:
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.NLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHCLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHCLG))
        break
      case AircraftType['A340-Family']:
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.NLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.CLDG))

        break
      default:
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.NLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHMLG))
      }
      break
    case Manufacturer.Boeing:
      switch (Number(transition.aircraftType)) {
      case AircraftType['747-Family']:
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.NLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHCLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHCLG))

        break
      default:
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.NLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHMLG))
        defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHMLG))
      }
      break
    case Manufacturer.Embraer:
    case Manufacturer.BombardierAerospace:
      defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.NLG))
      defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.RHMLG))
      defaults.push(getDefaultSubasset(TransitionComponent.LandingGear, SubassetPosition.LHMLG))

      break
    }

    setSubassets([
      ...subassets,
      ...defaults
    ])
  }

  return (
    <Grid container direction={'column'}>
      <Grid container item spacing={2}>
        <Grid item xs={6} >
          <ManufacturerSelect
            error={errors?.manufacturer}
            value={transition.manufacturer!}
            onChange={(manufacturer) => {
              onChange('manufacturer', manufacturer)
            }}/>
        </Grid>
        {
          Number(transition.manufacturer) === Manufacturer.Other &&
          <Grid item xs={12}>
            <Input
              required
              label={'Manufacturer name'}
              name={'manufacturerName'}
              value={transition.manufacturerName}
              errMsg={errors?.manufacturerName}
              onChange={(e: ChangeEvent<{ value: string }>) => onChange('manufacturerName', e.target.value)}
            />
          </Grid>
        }
        <Grid item xs={6} >
          <ModelSelect
            manufacturer={Number(transition.manufacturer)}
            error={errors?.aircraftType}
            value={transition.aircraftType!}
            onChange={(at) => {
              onChange('aircraftType', at)
            }}/>
        </Grid>
        <Grid item xs={6} >
          <AircraftSubtypeSelect
            aircraftType={Number(transition.aircraftType)}
            error={errors?.aircraftSubtype}
            value={transition.aircraftSubtype!}
            onChange={(at) => {
              onChange('aircraftSubtype', at)
            }}/>
        </Grid>
        <Grid item xs={6} >
          <AircraftVariantSelect
            aircraftSubtype={Number(transition.aircraftSubtype)}
            error={errors?.variation}
            value={transition.variation!}
            onChange={(at) => {
              onChange('variation', at)
            }}/>
        </Grid>
        <Grid item xs={6}>
          <FamilyTypeSelect
            // disabled={!isAircraftTypeDefined}
            value={transition.familyType || ''}
            onChange={(ft) => onChange('familyType', ft)}/>
        </Grid>
        <Grid item xs={6} >
        </Grid>
        <Grid item xs={6} >
          <Input
            required
            errMsg={errors?.msn}
            label={'MSN'}
            name={'msn'}
            value={transition.msn}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('msn', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} >
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'Previous Registration'}
            name={'registration'}
            errMsg={errors?.registration}
            value={transition.registration}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('registration', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'New Registration'}
            name={'registrationNew'}
            value={transition.registrationNew}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('registrationNew', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label={'Owner'}
            name={'owner'}
            value={transition.owner}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('owner', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <OwnershipTypesSelect
            value={Number(transition.ownershipStatus)}
            onChange={(os) => onChange('ownershipStatus', os)}/>
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'Previous Operator'}
            name={'previousOperator'}
            value={transition.previousOperator}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('previousOperator', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'New Operator'}
            name={'newOperator'}
            value={transition.newOperator}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('newOperator', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <AircraftStatusSelect
            value={transition.status!}
            onChange={(status) => onChange('status', status)}/>
        </Grid>
        <Grid item xs={6} >
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'Weight Variant'}
            name={'weightVariant'}
            value={transition.weightVariant}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('weightVariant', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'Weight Variant Group'}
            name={'weightVariantGroup'}
            value={transition.weightVariantGroup}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('weightVariantGroup', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} >
          <Input
            label={'MTOW(kg)'}
            name={'mtow'}
            value={transition.mtow}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('mtow', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            AIRCRAFT SUB-ASSETS
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Button
            id="subassets-menu-button"
            aria-controls={open ? 'subassets-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickSubassetsMenu}
            color="primary"
            size="small"
          >
            Add Sub-Asset
          </Button>
          <Menu
            id="subassets-menu"
            aria-labelledby="subassets-menu-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseSubassetsMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem key={'add-subasset-engine'} onClick={() => openModalForAddingAsset(TransitionComponent.Engine) }>
                Engine
            </MenuItem>
            <MenuItem key={'add-subasset-landinggear'} onClick={() => openModalForAddingAsset(TransitionComponent.LandingGear) }>
                Landing Gear
            </MenuItem>
            <MenuItem key={'add-subasset-apu'} onClick={() => openModalForAddingAsset(TransitionComponent.APU) }>
                APU
            </MenuItem>
            <MenuItem key={'add-subasset-ths'} onClick={() => openModalForAddingAsset(TransitionComponent.THSActuator) }>
              THS Actuator
            </MenuItem>
            <MenuItem key={'add-subasset-rat'} onClick={() => openModalForAddingAsset(TransitionComponent.RAT) }>
              RAT
            </MenuItem>
          </Menu>
        </Grid>
        <Grid container item xs={6}>
          <Button
            id="subassets-add-defaults-button"
            onClick={addDefaultSubassets}
            color="primary"
            size="small"
          >
            Add Defaults
          </Button>
        </Grid>
        {
          transition.subAssets !== undefined &&
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table >
                  <TableHead>
                    <TableRow className={classes.headRow}>
                      <TableCell >Sub-Asset Type</TableCell>
                      <TableCell >Serial Number</TableCell>
                      <TableCell >Position</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subassets.map((row, index) => (
                      <TableRow
                        key={`subassets-list-${index}`} >
                        <TableCell component="th" scope="row">
                          {TransitionComponent[row.transitionComponent!]}
                        </TableCell>
                        <TableCell >{row.msn}</TableCell>
                        <TableCell >{SubassetPosition[row.installedPosition!]}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            color="inherit"
                            size="small"
                            onClick={() => editSubasset(row, index)}>
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => removeSubasset(index)}>
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
        }
      </Grid>
      {assetType !== undefined &&
        <LisaModal
          modalType={'ASSET_MODAL'}
          title="Asset"
          onCancel={onAssetModalClosed}
          scroll='body'
          maxWidth="sm">
          <AssetModal assetType={assetType} transition={currSubasset} standalone={false} onSubassetAdded={subassetAdded} onSubassetUpdated={subassetUpdated} showTeams={showSubassetTeam} />
        </LisaModal>}
    </Grid>
  )
}
