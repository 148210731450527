import { ChangeEvent, FC, FormEvent, Fragment, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppBar, Button, Collapse, Grid, IconButton, makeStyles, Tab, Tabs, Tooltip, Typography } from '@material-ui/core'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { Nullable, ProjectId, SubassetPosition, Transition, TransitionComponent, UserListRow } from 'types'
import Input from 'common/LisaControls/Input'
import { AssetStatusSelect, EngineManufacturerSelect, EngineManufacturerTypeSelect, EngineManufacturerTypeVariantSelect, EnginePositionsSelect, EngineTypeSelect, LandingGearPositionsSelect, OwnershipTypesSelect } from 'pages/Administration/TransitionForm/TransitionElements'
import { useCreateOrEditTransitionMutation, useGetTransitionTeamQuery } from 'services/api/transitionApi'
import { Alert, TabContext, TabPanel } from '@material-ui/lab'
import theme from 'theme'
import { useGetUsersQuery } from 'services/api/usersApi'
import CloseIcon from '@material-ui/icons/Close'
import ComboBox from 'common/LisaControls/ComboBox'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    padding: '24px 0 32px 0',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    },
    '& .MuiAppBar-colorDefault': {
      background: theme.palette.common.white
    }
  },
  controlsWrapper: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
    padding: '0px !important'
  },
  actionButtons: {
    paddingTop: '32px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  appBar: {
    background: 'transparent',
    width: 'auto',
    color: 'rgba(0, 0, 0, 0.87)',
    '& .MuiTabs-root .MuiTab-textColorPrimary.Mui-selected': {
      borderBottom: `6px solid ${theme.palette.blue.main}`
    },
    '& .MuiTab-textColorPrimary.Mui-disabled > .MuiTab-wrapper': {
      color: theme.palette.black.light100
    },
    '& .MuiTabs-root .MuiTab-wrapper': {
      color: theme.palette.blue.main
    }
  },
  searchSpiner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCircularProgress-colorPrimary': {
      color: '#001E50 !important'
    }
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1101
  },
  userName: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 600
  },
  userRole: {
    marginBottom: '4px',
    '& .MuiAlert-message': {
      padding: '0',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.black.main
    },
    '& .MuiAlert-root': {
      padding: '5px 16px'
    },
    '& .MuiAlert-standardInfo': {
      background: 'white',
      '&:hover': {
        background: theme.palette.grey2.light
      }
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.black.main
    }
  },
  userList: {
    paddingBottom: '8px'
  },
  boxPadding: {
    padding: '0px'
  },
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    paddingLeft: '16px',
    borderRadius: '4px 4px 0 0',
    backgroundColor: `${theme.palette.grey2.main}`
  }
}))

type TransitionUsersListProps = {
  user?: string,
  remove: (val: string) => void
}

export const TransitionUsersList: FC<TransitionUsersListProps> = ({ user, remove }) => {
  const { data: allUsers = [] } = useGetUsersQuery()
  const classes = useStyles()
  const tooltipWrapper = (user: string) => {
    const result: UserListRow[] = allUsers.filter((item) => item.userId === user)
    if (!result[0]) {
      return null
    }

    return (
      <Tooltip
        placement='right-start'
        title={
          <div>
            {result[0].roles.map((role, i) => (
              <div key={role.roleId}>
                {`${role.name}`}{result[0].roles.length === i + 1 ? '' : ','}
              </div>
            ))}
          </div>
        }
      >
        <div>
          <div className={classes.userName}>{`${result[0].firstName} ${result[0].lastName}`}</div>
          <Typography variant='caption'>{result[0].email}</Typography>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={classes.userRole}>
      <Collapse in={true}>
        <Alert
          icon={false}
          severity='info'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={
                () => remove(user!)
              }
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {tooltipWrapper(user!)}
        </Alert>
      </Collapse>
    </div>
  )
}

export interface UsersListOption {
  value: string
  name: string
  email?: string
}

export interface FinalUsers {
  value: string
  name: string
  email?: string
}

type FormValues = Partial<Transition>

const initialFormValues: FormValues = {
  name: '',
  manufacturerName: '',
  engineManufacturer: 1,
  engineManufacturerType: 1,
  engineManufacturerTypeVariant: 1,
  engineType: 1,
  msn: '',
  partNumber: '',
  installedPosition: null,
  status: 1,
  ownershipStatus: 1,
  owner: '',
  previousOperator: '',
  newOperator: '',
  projectId: '',
  isActive: true,
  parentTransitionId: undefined
}

type FormErrors = {
  msn?: string,
  partNumber?: string
}

export interface AssetFormProps {
  assetType: TransitionComponent
  transition?: Partial<Transition> | undefined,
  standalone: boolean,
  projectId?: ProjectId,
  onSubassetAdded?: (subasset: Partial<Transition>) => void,
  onSubassetUpdated?: (subasset: Partial<Transition>) => void,
  showTeams?: boolean,
  showCancel?: boolean
}

export const AssetModal: FC<AssetFormProps> = ({ assetType, transition, standalone, projectId, onSubassetAdded, onSubassetUpdated, showTeams = false, showCancel = true }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { userId, currentTenant } = useLisaAuth()
  const isEdit = transition !== undefined
  const [createOrEditTransition] = useCreateOrEditTransitionMutation()
  const [activeTab, setActiveTab] = useState('0')
  const transitionId = transition?.transitionId ?? ''
  const [inputValue, setInputValue] = useState<string>('')
  const [newUserList, setNewUserList] = useState<string[]>([])
  const [selectedUser, setSelectedUser] = useState<UsersListOption>({ value: '', name: 'Select', email: '' })
  const { data: team = [] } = useGetTransitionTeamQuery(transitionId, { skip: !isEdit, refetchOnMountOrArgChange: true })
  const { data: allUsers = [] } = useGetUsersQuery()

  useEffect(() => {
    isEdit && team && setNewUserList(team.map(_ => _.userId))
  }, [team])

  const finalUserList: FinalUsers[] = allUsers.map(_ => ({
    value: _.userId,
    name: `${_.firstName} ${_.lastName}`,
    email: _.email
  }))
  const emptyUserList = { value: '', name: 'Select', email: '' }
  const dropdownUserList: UsersListOption[] = [emptyUserList, ...finalUserList]

  initialFormValues.transitionComponent = assetType
  initialFormValues.projectId = projectId
  if (assetType === TransitionComponent.Engine) {
    initialFormValues.installedPosition = SubassetPosition.E1
  } else if (assetType === TransitionComponent.LandingGear) {
    initialFormValues.installedPosition = SubassetPosition.LH
  } else {
    initialFormValues.installedPosition = null
  }

  const [_initialValues] = useState<FormValues>(isEdit
    ? {
      transitionId: transition.transitionId,
      transitionComponent: transition.transitionComponent,
      name: transition.name,
      manufacturerName: transition.manufacturerName,
      engineManufacturer: transition.engineManufacturer,
      engineManufacturerType: transition.engineManufacturerType,
      engineManufacturerTypeVariant: transition.engineManufacturerTypeVariant,
      msn: transition.msn,
      partNumber: transition.partNumber,
      engineType: transition.engineType,
      installedPosition: transition.installedPosition,
      status: transition.status,
      ownershipStatus: transition.ownershipStatus,
      owner: transition.owner,
      previousOperator: transition.previousOperator,
      newOperator: transition.newOperator,
      projectId: transition.projectId,
      isActive: transition.isActive,
      parentTransitionId: transition.parentTransitionId
    }
    : initialFormValues
  )

  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = assetType === TransitionComponent.Engine ? ['msn'] : ['msn', 'partNumber']

    const error = validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        }

        return { errors: e, isValid }
      }, { errors: {}, isValid: true })

    return error
  }, [])

  const {
    values,
    errors,
    handleInputChange,
    setValue,
    checkForErrors
  } = useLisaForm<FormValues, FormErrors>(
    { ..._initialValues },
    validate,
    false
  )

  const handleClose = () => {
    dispatch(closeModal(['ASSET_MODAL']))
  }

  async function saveTransition () {
    const { isError, message } = await createOrEditTransition({
      ...values,
      userId: userId!,
      users: newUserList,
      transitionId: transition?.transitionId,
      projectId,
      isActive: true,
      tenantId: currentTenant
    }).unwrap()

    if (!isError) {
      dispatch(openToast({
        severity: 'success',
        message: isEdit ? 'You have successfully updated an asset.' : 'You successfully created new asset.'
      }))
    }

    if (isError && message !== null) {
      dispatch(openToast({ severity: 'error', message: message }))
    }
  }

  const save = async (e: MouseEvent | FormEvent) => {
    e.preventDefault()

    const isValid = checkForErrors()
    if (isValid) {
      if (standalone) {
        await saveTransition()
      } else {
        if (isEdit) {
          onSubassetUpdated!(values)
        } else {
          onSubassetAdded!(values)
        }
      }

      handleClose()
    }
  }

  useEffect(() => {
    values.name = 'SN ' + values.msn
  }, [values.msn])

  const addUserToList = (item: string) => {
    if (!newUserList.includes(item)) {
      setNewUserList([...newUserList, item])
    }
  }

  const remove = (removeItem: string) => {
    setNewUserList(newUserList.filter((item) => item !== removeItem))
  }

  const handleUserInputChange = (item: UsersListOption) => {
    setSelectedUser(item)
  }

  return (
    <div className={classes.root}>
      <AppBar position='static' elevation={0} className={classes.appBar}>
        <TabContext value={activeTab}>
          <Tabs
            indicatorColor='primary'
            textColor='primary'
            centered
            value={activeTab}
            onChange={(event: ChangeEvent<{}>, v) => setActiveTab(v)}>
            {showTeams && <Tab value={'0'} label="Asset"/>}
            {showTeams && <Tab value={'1'} label="Team"/>}
          </Tabs>
          <TabPanel
            value={'0'}>
            <LisaForm onSubmit={save}>
              { assetType === TransitionComponent.Engine &&
               <Grid container spacing={2} >
                 <Grid item xs={6}>
                   <EngineManufacturerSelect
                     value={values.engineManufacturer!}
                     onChange={(engineManufacturer) => {
                       setValue('engineManufacturer', engineManufacturer)
                     }}/>
                 </Grid>
                 <Grid item xs={6}>
                   <EngineManufacturerTypeSelect
                     manufacturer={Number(values.engineManufacturer)}
                     value={values.engineManufacturerType!}
                     onChange={(engineManufacturerType) => {
                       setValue('engineManufacturerType', engineManufacturerType)
                     }}/>
                 </Grid>
                 <Grid item xs={6}>
                   <EngineManufacturerTypeVariantSelect
                     manufacturerType={Number(values.engineManufacturerType)}
                     value={Number(values.engineManufacturerTypeVariant)}
                     onChange={(engineManufacturerTypeVariant) => {
                       setValue('engineManufacturerTypeVariant', engineManufacturerTypeVariant)
                     }}/>
                 </Grid>
                 <Grid item xs={6} >
                   <EngineTypeSelect
                     value={values.engineType!}
                     onChange={(engineType) => setValue('engineType', engineType)
                     }/>
                 </Grid>
                 <Grid item xs={6} >
                   <Input
                     label={'Engine SN (ESN)'}
                     name={'msn'}
                     required
                     value={values.msn}
                     errMsg={errors?.msn}
                     onChange={handleInputChange}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <EnginePositionsSelect
                     value={Number(values.installedPosition)}
                     onChange={(position) => {
                       setValue('installedPosition', position)
                     }}/>
                 </Grid>
                 <Grid item xs={6}>
                   <Input
                     label={'Owner'}
                     name={'owner'}
                     value={values.owner}
                     onChange={handleInputChange}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <OwnershipTypesSelect
                     value={Number(values.ownershipStatus)}
                     onChange={(ownership) => {
                       setValue('ownershipStatus', ownership)
                     }}/>
                 </Grid>
                 <Grid item xs={6}>
                   <Input
                     label={'Previous Operator'}
                     name={'previousOperator'}
                     value={values.previousOperator}
                     onChange={handleInputChange}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <Input
                     label={'New Operator'}
                     name={'newOperator'}
                     value={values.newOperator}
                     onChange={handleInputChange}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <AssetStatusSelect
                     value={values.status!}
                     onChange={(status) => {
                       setValue('status', status)
                     }}/>
                 </Grid>
               </Grid>}
              { assetType === TransitionComponent.LandingGear &&
              <Grid container spacing={2} >
                <Grid item xs={6}>
                  <Input
                    label={'Manufacturer'}
                    name={'manufacturerName'}
                    value={values.manufacturerName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LandingGearPositionsSelect
                    value={Number(values.installedPosition)}
                    onChange={(position) => {
                      setValue('installedPosition', position)
                    }}/>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    errMsg={errors?.partNumber}
                    label={'Part Number'}
                    name={'partNumber'}
                    required
                    value={values.partNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Serial Number'}
                    name={'msn'}
                    required
                    errMsg={errors?.msn}
                    value={values.msn}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Owner'}
                    name={'owner'}
                    value={values.owner}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OwnershipTypesSelect
                    value={Number(values.ownershipStatus)}
                    onChange={(ownership) => {
                      setValue('ownershipStatus', ownership)
                    }}/>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Previous Operator'}
                    name={'previousOperator'}
                    value={values.previousOperator}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'New Operator'}
                    name={'newOperator'}
                    value={values.newOperator}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AssetStatusSelect
                    value={values.status!}
                    onChange={(status) => {
                      setValue('status', status)
                    }}/>
                </Grid>
              </Grid>}
              { (assetType === TransitionComponent.APU || assetType === TransitionComponent.RAT || assetType === TransitionComponent.THSActuator) &&
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <Input
                    label={'Manufacturer'}
                    name={'manufacturerName'}
                    value={values.manufacturerName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Part Number'}
                    name={'partNumber'}
                    required
                    errMsg={errors?.partNumber}
                    value={values.partNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Serial Number'}
                    name={'msn'}
                    required
                    errMsg={errors?.msn}
                    value={values.msn}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Owner'}
                    name={'owner'}
                    value={values.owner}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OwnershipTypesSelect
                    value={Number(values.ownershipStatus)}
                    onChange={(ownership) => {
                      setValue('ownershipStatus', ownership)
                    }}/>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'Previous Operator'}
                    name={'previousOperator'}
                    value={values.previousOperator}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label={'New Operator'}
                    name={'newOperator'}
                    value={values.newOperator}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AssetStatusSelect
                    value={values.status!}
                    onChange={(status) => {
                      setValue('status', status)
                    }}/>
                </Grid>
              </Grid>}
            </LisaForm>
            <div className={classes.actionButtons}>
              <Button type='submit' onClick={save} variant='contained' color='primary' size='small'>
                Save
              </Button>
              {showCancel && <Button onClick={handleClose} variant='contained' color='primary' size='small'>
                Cancel
              </Button>}
            </div>
          </TabPanel>
          <TabPanel
            // className={clsx({ [classes.tabPanel]: activeTab === ReportTabs.TRANSITION })}
            value={'1'}>
            <Grid container>
              <Grid item xs={12} className={classes.userList}>
                {newUserList &&
              newUserList.map((user, i) => (
                <TransitionUsersList
                  key={i}
                  user={user}
                  remove={(item: string) => remove(item)}
                />
              ))}
              </Grid>
              <Grid item xs={12} className={classes.controlsWrapper} >
                <Grid item xs={6}>
                  <ComboBox
                    label={'Select user'}
                    options={dropdownUserList}
                    getOptionLabel={(mtu: UsersListOption) => mtu.name ?? ''}
                    onInputChange={(e, newInputValue: string) => {
                      setInputValue(newInputValue)
                    }}
                    onChange={(e: ChangeEvent<{}>, newValue: Nullable<UsersListOption>) => {
                      newValue && handleUserInputChange(newValue)
                    }}
                    value={selectedUser}
                    renderOption={(option: UsersListOption) => (
                      <Fragment>
                        <div>
                          <div className={classes.userName}>{option.name}</div>
                          <Typography variant='caption'>{option.email}</Typography>
                        </div>
                      </Fragment>
                    )}
                    getOptionSelected={(opt, { value }) => opt.value === value}/>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    style={{ margin: '18px 0 0 8px' }}
                    disabled={(selectedUser && selectedUser.value === '') || inputValue.length === 0}
                    onClick={() => addUserToList(selectedUser.value!)}
                  >
                    ADD
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionButtons}>
              <Button type='submit' onClick={save} variant='contained' color='primary' size='small'>
                Save
              </Button>
              <Button onClick={handleClose} variant='contained' color='primary' size='small'>
                Cancel
              </Button>
            </div>
          </TabPanel>
        </TabContext>
      </AppBar>
    </div>
  )
}
