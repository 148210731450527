import { FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { TransitionForm } from './TransitionForm'
import { Typography } from '@material-ui/core'
import { openConfirmDialog, openModal, openToast, useApp } from 'redux/slices/appSlice'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { ProjectForm } from './ProjectForm'
import { Project, TransitionComponent } from 'types'
import { useArchiveProjectMutation, useGetSingleProjectQuery } from 'services/api/projectsApi'
import { AssetModal } from './AssetModal'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: '6px',
    fontSize: '16px',
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '18px',
      color: theme.palette.black.main
    }
  },
  dialogTItle: {
    '& .MuiTypography-h6': {
      lineHeight: 1
    }
  },
  dialogMessage: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '48px 0'
  },
  message: {
    fontSize: '20px',
    padding: '48px 0'
  }
}))

type ProjectsHeadMenuProps = {
  project: Project
}

export const ProjectsHeadMenu: FC<ProjectsHeadMenuProps> = ({ project }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editMode, setEditMode] = useState(false)
  const [editModeDialog, setEditModeDialog] = useState(false)
  const [editProjectModal, setEditProjectModal] = useState(false)
  const { activeModal } = useSelector(useApp)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { userId } = useLisaAuth()
  const [archiveProject] = useArchiveProjectMutation()
  const { data: singleProject, isLoading } = useGetSingleProjectQuery(project.projectId, { skip: !editProjectModal })

  const [assetType, setAssetType] = useState<TransitionComponent | undefined>(undefined)

  function onAssetModalClosed () {
    setAssetType(undefined)
  }

  useEffect(() => {
    if (editMode) {
      dispatch(openModal('TRANSITION_FORM_PROJECTS'))
    }
  }, [editMode])

  useEffect(() => {
    if (editModeDialog) {
      dispatch(openConfirmDialog('DEACTIVATE_PROJECT'))
    }
  }, [editModeDialog])

  useEffect(() => {
    if (editProjectModal && !isLoading) {
      dispatch(openModal('PROJECT_FORM_EDIT'))
    }
  }, [editProjectModal, isLoading])

  useEffect(() => { // @todo to handle cancel or X of modal
    if (activeModal.length === 0) {
      setEditMode(false)
      setEditModeDialog(false)
      setEditProjectModal(false)
      setAssetType(undefined)
    }
  }, [activeModal.length])

  const handleProjectSettings = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseProjectSettings = () => {
    setAnchorEl(null)
  }

  const handleArchiveProject = () => {
    setEditModeDialog(true)
    handleCloseProjectSettings()
  }

  const handleEditProject = () => {
    setEditProjectModal(true)
    handleCloseProjectSettings()
  }

  const handleAddTransition = () => {
    setEditMode(true)
    handleCloseProjectSettings()
  }

  const handleNewAsset = (type: TransitionComponent) => {
    setAssetType(type)
    dispatch(openModal('ASSET_MODAL'))
    handleCloseProjectSettings()
  }

  const handleConfirmArchive = async () => {
    const { isError, message } = await archiveProject({
      projectId: project.projectId,
      name: project.name,
      userId
    }).unwrap()

    if (isError && message) {
      dispatch(openToast({ severity: 'error', message }))
    } else {
      dispatch(openToast({
        severity: 'success',
        message: 'You successfully deactivated a entity.'
      }))
    }
  }

  return (
    <div className='menu-item' onClick={e => e.stopPropagation()}>

      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        className={classes.iconButton}
        onClick={handleProjectSettings}
      >
        <Icon className='fas fa-ellipsis-v' fontSize='small' />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        elevation={8}
        open={Boolean(anchorEl)}
        onClose={handleCloseProjectSettings}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleAddTransition}>Add Aircraft</MenuItem>
        <MenuItem onClick={() => { handleNewAsset(TransitionComponent.Engine) }}>Add Engine</MenuItem>
        <MenuItem onClick={() => { handleNewAsset(TransitionComponent.LandingGear) }}>Add Landing Gear</MenuItem>
        <MenuItem onClick={() => { handleNewAsset(TransitionComponent.APU) }}>Add APU</MenuItem>
        <MenuItem onClick={() => { handleNewAsset(TransitionComponent.THSActuator) }}>Add THS Actuator</MenuItem>
        <MenuItem onClick={() => { handleNewAsset(TransitionComponent.RAT) }}>Add RAT</MenuItem>
        <MenuItem onClick={handleArchiveProject} disabled={project.transitionCount > 0}>Deactivate Entity</MenuItem>
        <MenuItem onClick={handleEditProject}>Edit Entity</MenuItem>
      </Menu>
      {editMode && <LisaModal
        title={'Add new Asset'}
        scroll={'body'}
        modalType={'TRANSITION_FORM_PROJECTS'}>
        <TransitionForm
          projectId={project.projectId}
        />
      </LisaModal>}
      {editModeDialog && <ConfirmDialog
        title='Deactivate entity'
        type={'DEACTIVATE_PROJECT'}
        onConfirm={handleConfirmArchive}>
        <div className={classes.message}>
          <Typography variant='inherit'>Are you sure you want to deactivate this entity?</Typography>
        </div>
      </ConfirmDialog>
      }
      {editProjectModal && <LisaModal
        title={'Edit Entity'}
        modalType={'PROJECT_FORM_EDIT'}
        scroll='body'>
        <ProjectForm
          project={singleProject}
        />
      </LisaModal>}
      {assetType !== undefined &&
        <LisaModal
          modalType={'ASSET_MODAL'}
          title="Asset"
          onCancel={onAssetModalClosed}
          scroll='body'
          maxWidth="sm">
          <AssetModal assetType={assetType} standalone={true} projectId={project.projectId}/>
        </LisaModal>}
    </div>
  )
}
