import { FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { Typography } from '@material-ui/core'
import { TransitionForm } from './TransitionForm'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { openConfirmDialog, openModal, openToast, useApp } from 'redux/slices/appSlice'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { setCurrentTransitionId } from 'redux/slices/transitionSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { ProjectId, Transition, TransitionComponent } from 'types'
import { useArchiveTransitionMutation, useGetTransitionQuery } from 'services/api/transitionApi'
import { AssetModal } from './AssetModal'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: '6px',
    fontSize: '16px',
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '18px',
      color: theme.palette.black.main
    }
  },
  dialogTItle: {
    '& .MuiTypography-h6': {
      lineHeight: 1
    }
  },
  dialogMessage: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '48px 0'
  },
  message: {
    fontSize: '20px',
    padding: '48px 0'
  }
}))

type TransitionHeadMenuProps = {
  projectId: ProjectId,
  singleTransition: Partial<Transition>,
  isActive: boolean
}

export const TransitionMenu: FC<TransitionHeadMenuProps> = ({
  projectId,
  singleTransition,
  isActive
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editMode, setEditMode] = useState(false)
  const [editModeDialog, setEditModeDialog] = useState(false)
  const { activeModal } = useSelector(useApp)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { userId } = useLisaAuth()
  const [archiveTransition] = useArchiveTransitionMutation()
  const [assetType, setAssetType] = useState<TransitionComponent | undefined>(undefined)
  // const { data: transition, isLoading } = useGetTransitionQuery(singleTransition.transitionId, { skip: !editMode })

  const { data: transition, isLoading } = useGetTransitionQuery(singleTransition.transitionId!)

  function onAssetModalClosed () {
    setAssetType(undefined)
  }

  useEffect(() => {
    if (editMode && !isLoading) {
      dispatch(openModal('TRANSITION_FORM_MENU'))
    }
  }, [editMode, isLoading])

  useEffect(() => {
    if (editModeDialog) {
      dispatch(openConfirmDialog('DEACTIVATE_TRANSITION'))
    }
  }, [editModeDialog])

  useEffect(() => { // to handle cancel or X of modal
    if (activeModal.length === 0) {
      setEditMode(false)
      setEditModeDialog(false)
      setAssetType(undefined)
    }
  }, [activeModal.length])

  const handleCloseTransitionSettings = () => {
    setAnchorEl(null)
  }

  const handleProjectSettings = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (assetType !== undefined) {
      dispatch(openModal('ASSET_MODAL'))
    }
  }, [assetType])

  const handleEditTransition = () => {
    if (transition?.transitionComponent === TransitionComponent.Aircraft) {
      setEditMode(true)
    } else {
      setAssetType(singleTransition!.transitionComponent)
    }

    handleCloseTransitionSettings()
  }

  const handleArchiveTransition = () => {
    setEditModeDialog(true)
    handleCloseTransitionSettings()
  }

  return (
    <div className="menu-item">
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.iconButton}
        onClick={handleProjectSettings}
      >
        <Icon className="fas fa-ellipsis-v" fontSize="small"/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={8}
        open={Boolean(anchorEl)}
        onClose={handleCloseTransitionSettings}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          onClick={handleEditTransition}
        >
          Edit Asset{' '}
        </MenuItem>
        <MenuItem
          disabled={!isActive}
          onClick={handleArchiveTransition}
        >
          Deactivate Asset
        </MenuItem>
      </Menu>
      {
        editMode &&
        <LisaModal
          title={'Edit Asset'}
          scroll={'body'}
          modalType={'TRANSITION_FORM_MENU'}>
          <TransitionForm
            projectId={projectId}
            transition={transition}
          />
        </LisaModal>
      }
      {
        editModeDialog &&
        <ConfirmDialog
          title="Deactivate Asset"
          type={'DEACTIVATE_TRANSITION'}
          onCancel={() => setEditModeDialog(false)}
          onConfirm={async () => {
            const { isError } = await archiveTransition({
              projectId,
              transitionId: singleTransition.transitionId!,
              userId
            }).unwrap()
            if (!isError) {
              dispatch(openToast({ severity: 'success', message: 'You successfully deactivated asset' }))
              dispatch(setCurrentTransitionId(null))
            }
          }}>
          <div className={classes.message}>
            <Typography variant="inherit">Are you sure you want to deactivate this asset?</Typography>
          </div>
        </ConfirmDialog>
      }
      {assetType !== undefined && transition !== undefined &&
        <LisaModal
          modalType={'ASSET_MODAL'}
          title="Asset"
          onCancel={onAssetModalClosed}
          scroll='body'
          maxWidth="sm">
          <AssetModal assetType={assetType} transition={singleTransition} standalone={true} projectId={projectId} />
        </LisaModal>}
    </div>
  )
}
