import { useEffect, useState } from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ListTabs from './components/Tabs'
import Thumbnail from './components/Thumbnail'
import * as Icons from 'common/Icons/SvgIcons'
import { Grid, Theme, Tooltip, useMediaQuery, useTheme } from '@material-ui/core'
import { DocumentProvider } from 'context/DocumentContext'
import { TransitionProvider } from 'context/TransitionContext'
import { DocumentId, ParserStatus, TransitionId } from 'types'
import {
  useDocumentViewMode
} from 'redux/slices/documentSlice'
import { DocumentViewer } from 'common/DocumentViewer/DocumentViewer'
import { setHierarchy, useDocumentLabels } from 'redux/slices/documentLabelsSlice'
import { WithCircularLoader } from 'common/WithLoader/WithCircularLoader'
import { closeToast, openToast } from 'redux/slices/appSlice'
import {
  useCheckParserStatusQuery,
  useGetDocumentMetaDataQuery,
  useGetDocumentQuery,
  useReplaceDocumentMutation
} from 'services/api/documentApi'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useGetDocumentLabelsQuery } from 'services/api/labellingApi'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles<Theme, { open: boolean }>((theme) => ({
  toggleButton: {
    width: '32px',
    height: '96px',
    right: '360px',
    position: 'absolute',
    top: 'calc(50% - 48px)',
    zIndex: 1024,
    opacity: '.6',
    marginLeft: '-32px',
    borderLeft: `1px solid ${theme.palette.grey2.dark}`,
    borderTop: `1px solid ${theme.palette.grey2.dark}`,
    borderBottom: `1px solid ${theme.palette.grey2.dark}`,
    borderRadius: '4px 0 0 4px',
    backgroundImage: `linear-gradient(90deg, ${theme.palette.grey2.main} 80%, ${theme.palette.grey2.dark} 120%)`,
    marginRight: '0px',
    '&:hover': {
      opacity: '1',
      borderLeft: `1px solid ${theme.palette.lime.dark}`,
      borderTop: `1px solid ${theme.palette.lime.dark}`,
      borderBottom: `1px solid ${theme.palette.lime.dark}`,
      backgroundImage: `linear-gradient(90deg, ${theme.palette.lime.main} 80%, ${theme.palette.grey2.dark} 120%)`
    },
    '&.toggleButtonClosed': {
      right: '0px'
    }
  },
  chevron: {
    transition: 'all .2s linear',
    transform: 'rotate(0deg)'
  },
  chevronFlip: {
    transition: 'all .2s linear',
    transform: 'rotate(-180deg)'
  },
  docViewLogo: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    width: '220px',
    height: '56px',
    background: theme.palette.blue.main,
    '& a': {
      display: 'flex'
    }
  },
  docLogoImg: {
    margin: '9px',
    height: '38px',
    width: '38px',
    fill: theme.palette.lime.main
  },
  docLogoType: {
    height: '56px',
    width: 'auto',
    fill: theme.palette.common.white
  },
  transitionName: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '56px',
    fontSize: '16px',
    padding: '12px 16px',
    cursor: 'default',
    '& .MuiSvgIcon-root': {
      marginRight: '6px'
    },
    '& .transitionLabel': {
      width: 155,
      fontWeight: 600,
      letterSpacing: '-.25px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  left: {
    minWidth: 220,
    maxWidth: 220,
    flex: '0 1 auto',
    backgroundColor: theme.palette.grey2.light
  },
  middle: {
    flex: '1 1 auto',
    backgroundColor: '#464646',
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxWidth: (props) => `calc(100vw - 220px - ${props.open ? '360px' : '0px'})`,
    width: (props) => `calc(100vw - 220px - ${props.open ? '360px' : '0px'})`
  },
  right: {
    flex: '0 1 auto',
    minWidth: 360,
    maxWidth: 360
  },
  closedMenu: {
    display: 'none'
  }
}))

/**
 * @todo This needs additional refactoring to break down in smaller components
 * @constructor
 */
export default function Document () {
  const [open, setOpen] = useState(true)
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(undefined)
  const classes = useStyles({ open })
  const history = useHistory()
  const dispatch = useDispatch()
  const search = window.location.search // returns the URL query String
  const params = new URLSearchParams(search)
  const IdFromURL = params.get('tId')! as TransitionId
  const transitionId = IdFromURL
  const { id: documentId } = useParams<{ id: DocumentId }>() // @todo DocumentRoutes
  const { data: transition, isLoading: isTransitionLoading } = useGetTransitionQuery(transitionId)
  const viewMode = useSelector(useDocumentViewMode)
  const { selected: labels } = useSelector(useDocumentLabels)
  const {
    hasAccess,
    userId
  } = useLisaAuth()
  const { data: parserStatus } = useCheckParserStatusQuery(documentId)

  const {
    data: document,
    isLoading
  } = useGetDocumentQuery({
    transitionId: transitionId,
    id: documentId
  }, {
    skip: !transitionId || !documentId,
    pollingInterval: pollingInterval
  })
  const {
    data: { references: documentReferences } = { references: [] },
    isLoading: isLoadingDocumentMetaData
  } = useGetDocumentMetaDataQuery(documentId)
  const { isLoading: isReplacingInProgress } = useReplaceDocumentMutation()[1]
  const { data: documentLabels, fulfilledTimeStamp } = useGetDocumentLabelsQuery({ documentId, transitionId }, {
    skip: !documentId
  })

  const loaded = !isLoading && !isLoadingDocumentMetaData && !isTransitionLoading

  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  useEffect(() => {
    setOpen(!isMedium)
  }, [isMedium])

  useEffect(() => {
    if (document?.name) {
      window.document.title = document?.name
    }
    return () => {
      window.document.title = 'LISA Records Management'
    }
  }, [document?.name])

  useEffect(() => {
    if (documentLabels !== undefined) {
      dispatch(setHierarchy(documentLabels))
    }
  }, [fulfilledTimeStamp])

  useEffect(() => {
    if (document !== undefined && !document.excelConverterEnabled && !document.excelUrl) {
      setPollingInterval(60 * 1000)
    } else {
      if (pollingInterval !== undefined) {
        dispatch(openToast({
          severity: 'info', message: `Document ${document?.name} has been converted to excel successfully`
        }))
      }
      setPollingInterval(undefined)
    }
  }, [document?.excelConverterEnabled, document?.excelUrl])

  useEffect(() => {
    if (userId && document?.documentId) {
      if (document.message === '401') {
        dispatch({ type: 'UNSET_CURRENT_TRANSITION' })
        dispatch(openToast({
          severity: 'error',
          message: 'You don\'t have permissions to this document, this document will be closed!'
        }))
        setTimeout(() => {
          dispatch(closeToast())
          hasAccess('perm_view_query')
            ? history.push('/dashboard')
            : history.push('/dashboard/my-transitions')
        }, 3000)
      }
    }
  }, [document?.documentId, userId, document?.message])

  const handleDrawerOpen = () => {
    setOpen(!open)
  }
  return (
    <TransitionProvider transitionId={IdFromURL!}>
      {
        <DocumentProvider>
          <Grid
            direction={'row'}
            wrap={'nowrap'}
            alignContent={'flex-start'}
            alignItems={'stretch'}
            container>
            {/* left side */}
            <Grid
              item
              className={classes.left}>
              <Grid container direction={'column'}>
                <div className={classes.docViewLogo}>
                  <Link to="/dashboard" rel="noopener noreferrer">
                    <Icons.LogoType className={classes.docLogoType}/>
                  </Link>
                </div>
                <Tooltip
                  title={transition?.name ?? ''}
                  placement="right"
                >
                  <div className={classes.transitionName}>
                    <Icons.PlaneIco/>
                    <span className={'transitionLabel'}>{transition?.name ?? ''}</span>
                  </div>
                </Tooltip>
                <div>
                  <WithCircularLoader loading={!loaded}>
                    <Thumbnail/>
                  </WithCircularLoader>
                </div>
              </Grid>
            </Grid>
            {/* middle */}
            <Grid
              item
              className={classes.middle}>
              <WithCircularLoader
                loading={!loaded}
                fullPage
                text={isReplacingInProgress ? 'Replacing document' : ''}>
                {
                  document &&
                  <DocumentViewer
                    document={document}
                    labels={labels}
                    loadingDocument={!loaded}
                    documentReferences={documentReferences}
                    options={{
                      tableView: viewMode === 'datatable',
                      datatableParsed: parserStatus === ParserStatus.Success
                    }}/>
                }
              </WithCircularLoader>

            </Grid>
            {/* right side */}
            <Grid
              item
              xs
              className={clsx(classes.right, { [classes.closedMenu]: !open })}>
              <WithCircularLoader loading={!loaded}>
                <ListTabs/>
              </WithCircularLoader>
            </Grid>
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(classes.toggleButton, {
                  toggleButtonClosed: !open
                })}
              >
                {open
                  ? (
                    <Icons.ChevronIco className={classes.chevron}/>
                  )
                  : (
                    <Icons.ChevronIco className={classes.chevronFlip}/>
                  )}
              </IconButton>
            </div>
          </Grid>
        </DocumentProvider>
      }
    </TransitionProvider>
  )
}
