import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useDashboard } from 'redux/slices/dashboardSlice'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'
import { MyTransitionsFilter } from './components/MyTransitionsFilter'
import {
  filterTransitionsByName,
  filterTransitionsByProjectId,
  filterTransitionsByUser,
  sortTransitionsByCreateDate
} from 'utils/transition'
import { useGetMyTransitionsQuery } from 'services/api/transitionApi'
import { TransitionsDataTable } from 'pages/Administration/components/projects/TransitionsDataTable'
import { ChangeEvent, useEffect, useState } from 'react'
import theme from 'theme'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Project, TransitionItem } from 'types'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiAccordion-root': {
      marginBottom: '8px',
      '&:before': {
        display: 'none'
      },
      '&.Mui-expanded': {
        margin: '32px 0',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.08)',
        '&:first-child': {
          margin: '0 0 24px 0'
        }
      },
      '& .MuiAccordionSummary-root': {
        padding: '2px 16px 2px 6px',
        flexDirection: 'row-reverse',
        '&.Mui-expanded': {
          minHeight: '0px !important',
          borderBottom: `3px solid ${theme.palette.lime.main}`,
          borderRadius: '4px 4px 0 0'
        }
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
        '&.Mui-expanded': {
          margin: '12px 0',
          minHeight: '0px'
        }
      }
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 0px'
    }
  },
  projectsRoot: {
    marginTop: '10px'
  },
  projectName: {
    fontSize: '18px',
    marginLeft: '12px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& .transitionCount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '13px',
      lineHeight: '0',
      height: '18px',
      minWidth: '18px',
      fontWeight: '600',
      color: theme.palette.black.main,
      padding: '4px',
      background: theme.palette.lime.main,
      borderRadius: '4px',
      marginLeft: '6px'
    }
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  projectAccordion: {
    borderRadius: '4px !important',
    border: 'none !important',
    margin: '0',
    '&:hover': {
      background: theme.palette.grey2.light
    }
  }
}))

const MyTransitions = () => {
  const classes = useStyles()
  const { userId } = useLisaAuth()
  const { myTransitionsFilters } = useSelector(useDashboard)
  const { data: myTransitions = [], isLoading } = useGetMyTransitionsQuery(userId)
  const [expanded, setExpanded] = useState<string | false>(false)
  // const { data: allProjects = [], isFetching } = useGetAllProjectsQuery(undefined, { skip: !!searchTerm })
  // const { data: searchProjects = [], isFetching: isSearching } = useGetAllProjectsSearchQuery(searchTerm, { skip: !searchTerm })
  // const projects = !searchTerm ? allProjects : searchProjects
  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [projects, setProjects] = useState<Project[]>([])
  const [transitions, setTransitions] = useState<TransitionItem[]>([])

  useEffect(() => {
    setTransitions(
      filterTransitionsByProjectId(
        filterTransitionsByName(
          filterTransitionsByUser(
            sortTransitionsByCreateDate(myTransitions, myTransitionsFilters.sort),
            myTransitionsFilters.userId
          ),
          myTransitionsFilters.term),
        myTransitionsFilters.projectId)
    )
  }, [myTransitionsFilters, isLoading])

  useEffect(() => {
    let projects:Project[] = []

    projects = transitions.map((transition) => {
      const proj:Project = {
        projectId: transition.projectId,
        name: transition.projectName,
        transitionCount: transitions.filter(x => x.projectId === transition.projectId).length,
        description: '',
        isActive: true,
        isError: false,
        message: '',
        transitions: [],
        userId: ''
      }

      return proj
    })

    const uniqueProjects = projects.reduce((acc:Project[], obj:Project) => {
      if (!acc.some((item:Project) => item.projectId === obj.projectId)) {
        acc.push(obj)
      }
      return acc
    }, [])

    setProjects(uniqueProjects)
  }, [transitions])

  return (
    <ContentWrapper newLayout>
      <div className={classes.root}>
        {
          myTransitions.length > 0 &&
            <MyTransitionsFilter/>
        }
        <WithDocumentsLoader
          className={classes.projectsRoot}
          loading={isLoading}
          hasResults={projects.length > 0}
          loadingText={'Loading entities...'}
          noResultsProps={{ message: 'No entities' }}>
          {projects?.map((project: Project) => (
            <Accordion
              TransitionProps={{ unmountOnExit: true, appear: true }}
              className={classes.projectAccordion}
              key={project.projectId}
              expanded={expanded === project.projectId}
              onChange={handleChange(project.projectId)}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${project.projectId}bh-content`}
                id={`${project.projectId}bh-header`}>
                <span className={classes.projectName}>{project.name}
                  <span className={'transitionCount'}>{project.transitionCount}</span>
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <TransitionsDataTable
                  project={project} canEdit={false}/>
              </AccordionDetails>
            </Accordion>
          ))}
        </WithDocumentsLoader>
      </div>
    </ContentWrapper>
  )
}

export default MyTransitions
