import { FC, useMemo } from 'react'
import SettingsPageWrapper from 'pages/Settings/SettingsPageWrapper'
import { Grid, makeStyles } from '@material-ui/core'
import { useTransitionId } from 'context/TransitionContext'
import {
  useCreateOrEditTransitionMutation,
  useGetTransitionQuery,
  useGetTransitionTeamQuery
} from 'services/api/transitionApi'
import { TransitionForm } from 'components/Transitions/TransitionForm'
import { openToast } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useDispatch } from 'react-redux'
import { WithTransitionsLoader } from 'common/WithLoader/WithLoader'
import clsx from 'clsx'
import { TransitionComponent } from 'types'
import { AssetModal } from 'pages/Administration/components/projects/AssetModal'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  gridForm: {
    maxWidth: '600px',
    alignSelf: 'center'
  },
  gridFormLoading: {
    flex: 1
  }
}))
export const TransitionSettings: FC = () => {
  const transitionId = useTransitionId()
  const dispatch = useDispatch()
  const { userId } = useLisaAuth()
  const history = useHistory()

  const { data: transition, isLoading } = useGetTransitionQuery(transitionId)
  const [editTransition, { isLoading: isSaving }] = useCreateOrEditTransitionMutation()
  const { data: transitionTeam = [], isLoading: isLoadingTransitionTeam } = useGetTransitionTeamQuery(transitionId)
  const classes = useStyles()
  const loading = isSaving || isLoadingTransitionTeam || isLoading
  const loaderMsg = useMemo(() => {
    if (isLoadingTransitionTeam || isLoading) {
      return 'Loading asset...'
    }
    if (isSaving) {
      return 'Updating asset...'
    }
    return ''
  }, [isSaving, isLoadingTransitionTeam || isLoading])

  return (
    <SettingsPageWrapper>
      <Grid container justifyContent='center' className={clsx({ [classes.gridFormLoading]: loading })}>
        <Grid item className={clsx(classes.gridForm)}>
          <WithTransitionsLoader loading={loading} hasResults={true} loadingText={loaderMsg}>
            {transition?.transitionComponent === TransitionComponent.Aircraft && <TransitionForm
              transition={transition}
              isLoading={isSaving}
              showSubassetTeam={false}
              onSave={async (values) => {
                if (!transition) {
                  return { isError: true, message: 'Asset not found!' }
                }
                const editValues = { ...values }
                delete editValues.users
                const { isError, message } = await editTransition({
                  ...editValues,
                  name: values.name?.trim(),
                  userId,
                  users: transitionTeam.map(_ => _.userId),
                  transitionId: transition.transitionId,
                  projectId: transition.projectId,
                  isActive: true
                }).unwrap()
                if (!isError) {
                  dispatch(openToast({
                    severity: 'success',
                    message: 'You have successfully updated asset.'
                  }))

                  history.go(0)
                }
                return { isError, message }
              }}
            />}
            {transition?.transitionComponent !== TransitionComponent.Aircraft &&
                <AssetModal assetType={transition?.transitionComponent!} standalone={true} transition={transition} projectId={transition?.projectId} showCancel={false} />
            }
          </WithTransitionsLoader>
        </Grid>
      </Grid>
    </SettingsPageWrapper>
  )
}
