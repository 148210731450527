import { ChangeEvent, FC, useMemo, useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { TransitionMenu } from './TransitionMenu'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@material-ui/core'
import { AircraftSubtype, AircraftType, AircraftVariant, AssetStatus, EngineManufacturer, EngineManufacturerType, EngineManufacturerTypeVariant, FamilyType, Manufacturer, Project, SubassetPosition, Transition, TransitionComponent, TransitionId, TransitionOwnershipType, TransitionType } from 'types'
import { TableData } from 'common/TableData/TableData'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { WithPagination } from 'common/WithPagination/WithPagination'
import { aircraftTypeItems } from 'pages/Administration/TransitionForm/TransitionElements'
import { useGetSingleProjectQuery } from 'services/api/projectsApi'
import { openModal } from 'redux/slices/appSlice'
import { useDispatch } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    margin: '0px !important',
    '& .MuiGrid-container': {
      margin: '0px !important'
    },
    '& .MuiTableCell-root': {
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '.05px',
      fontWeight: '700',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  bodyRows: {
    height: '46px',
    borderBottomStyle: 'dotted',
    borderTopStyle: 'dotted'
  },
  bodyRowsSubassets: {
    height: '26px'
  },
  transitionName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
    width: '180px',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.statusColors.blue} !important`,
      textDecoration: 'underline'
    },
    '& span': {
      paddingLeft: 8,
      paddingTop: 1,
      height: 15,
      maxWidth: 250,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  firstCell: {
    paddingLeft: '16px !important'
  },
  tableText: {
    fontSize: '16px',
    fontWeight: 500
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '55px',
    padding: '8px 0',
    '& .Mui-selected': {
      background: theme.palette.blue.main,
      color: theme.palette.common.white
    }
  },
  newTransitionButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0px'
  },
  paginationContainer: {
    height: 48,
    width: '100%'
  },
  aircraft: {
    backgroundColor: '#4DB8F0',
    overflow: 'visible !important;'
  },
  engine: {
    backgroundColor: '#4DDC7B',
    overflow: 'visible !important;'
  },
  landingGear: {
    backgroundColor: '#B568C4',
    overflow: 'visible !important;'
  },
  apu: {
    backgroundColor: '#F1A289',
    overflow: 'visible !important;'
  },
  rat: {
    backgroundColor: '#A2E2DA',
    overflow: 'visible !important;'
  },
  ths: {
    backgroundColor: theme.palette.statusColors.red,
    overflow: '#A394D9'
  }
}))

type DataTableProps = {
  project: Project,
  canEdit?: boolean
}

export const TransitionsDataTable: FC<DataTableProps> = ({
  project,
  canEdit = true
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const history = useHistory()
  const { hasAccess } = useLisaAuth()
  const hasTransitions = project.transitionCount > 0
  const {
    data: singleProject,
    isLoading
  } = useGetSingleProjectQuery(project.projectId, { skip: !hasTransitions })
  const transitions = singleProject?.transitions.filter(x => x.parentTransitionId === null) ?? []
  // const transitions = singleProject?.transjitions ?? []

  const canViewA2k = hasAccess('perm_view_a2kstructure')

  const openTransition = (transitionId: TransitionId) => {
    history.push(`/structures/${canViewA2k ? 'import' : 'client'}/${transitionId}`)
  }
  const isDecimal = (transitions.length / rowsPerPage) % 1 !== 0
  const pages = Math.floor(transitions.length / rowsPerPage) + (isDecimal ? 1 : 0)

  if (!hasTransitions && canEdit) {
    return (
      <div className={classes.newTransitionButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(openModal('TRANSITION_FORM'))
          }}>
          ADD ASSET
        </Button>
      </div>
    )
  }

  const [expanded, setExpanded] = useState<string | boolean>(false)
  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  if (transitions.length === 0) {
    return null
  }

  return (
    <Grid container direction={'column'} className={classes.root}>
      <TableData
        noShadow
        noGrow
        tinyHeading
        loading={isLoading}
        loadingText="Loading Assets"
        noResultsProps={{
          type: 'transitions',
          message: 'No assets'
        }}
        headCells={[
          <TableCell key="asset" width={'15%'} className={classes.firstCell}>
            ASSET
          </TableCell>,
          <TableCell key="registration" width={'6%'} align="left">
            REGISTRATION
          </TableCell>,
          <TableCell key="assettype" width={'7%'} align="left">
            ASSET TYPE
          </TableCell>,
          <TableCell key="manufacturer" width={'8%'} align="left">
            MANUFACTURER
          </TableCell>,
          <TableCell key="model" width={'7%'} align="left">
            MODEL
          </TableCell>,
          <TableCell key="subtype" width={'7%'} align="left">
            SUB-TYPE
          </TableCell>,
          <TableCell key="variant" width={'7%'} align="left">
            VARIANT
          </TableCell>,
          <TableCell key="family" width={'7%'} align="left">
            FAMILY
          </TableCell>,
          <TableCell key="position" width={'6%'} align="left">
            POSITION
          </TableCell>,
          <TableCell key="status" width={'6%'} align="left">
            STATUS
          </TableCell>,
          <TableCell key="owner" width={'7%'} align="left">
            OWNER
          </TableCell>,
          <TableCell key="ownershiptype" width={'7%'} align="left">
            OWNERSHIP
          </TableCell>,
          <TableCell key="team" width={'6%'} align="left">
            TEAM
          </TableCell>,
          canEdit ? <TableCell key="menu" width={'4%'} /> : []
        ]}
        tableRows={
          transitions.slice((page - 1) * rowsPerPage, page * rowsPerPage)
            .map((row) => {
              return (
                <Fragment key={row.transitionId + 'asset'}>
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.transitionId}
                    className={classes.bodyRows}>
                    <TableCell
                      id={row.userId}
                      align={'left'}
                      width={'15%'}
                      style={{ paddingLeft: 16 }}>
                      <div className={classes.transitionName}
                        onClick={() => row.isActive ? openTransition(row.transitionId) : null}>
                        {row.transitionComponent === TransitionComponent.Aircraft && <span className={classes.aircraft}>AC&nbsp;&nbsp;</span>}
                        {row.transitionComponent === TransitionComponent.Engine && <span className={classes.engine}>ENG&nbsp;&nbsp;</span>}
                        {row.transitionComponent === TransitionComponent.LandingGear && <span className={classes.landingGear}>LDG&nbsp;&nbsp;</span>}
                        {row.transitionComponent === TransitionComponent.APU && <span className={classes.apu}>APU&nbsp;&nbsp;</span>}
                        {row.transitionComponent === TransitionComponent.RAT && <span className={classes.rat}>RAT&nbsp;&nbsp;</span>}
                        {row.transitionComponent === TransitionComponent.THSActuator && <span className={classes.ths}>THS&nbsp;&nbsp;</span>}
                        <span>{row.name}</span>
                      </div>
                    </TableCell>
                    <TableCell width={'6%'} align="left">
                      <span className={classes.tableText}>
                        {row.registration || '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>
                        {TransitionComponent[row.transitionComponent] || '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'8%'} align="left">
                      <span className={classes.tableText}>
                        { row.manufacturer !== undefined ? Manufacturer[row.manufacturer!] : (row.manufacturerName !== undefined ? row.manufacturerName : '-') }
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>
                        {row.transitionComponent === TransitionComponent.Aircraft
                          ? (AircraftType[row.aircraftType!] || '-')
                          : '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>
                        {row.transitionComponent === TransitionComponent.Aircraft
                          ? (AircraftSubtype[row.aircraftSubtype!] || '-')
                          : '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>
                        {row.transitionComponent === TransitionComponent.Aircraft
                          ? (AircraftVariant[row.variation!] || '-')
                          : '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>-</span>
                    </TableCell>
                    <TableCell width={'6%'} align="left">
                      <span className={classes.tableText}>
                        {SubassetPosition[row.installedPosition!] || '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'6%'} align="left">
                      <span className={classes.tableText}>
                        {AssetStatus[row.status!]}
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>
                        {row.owner || '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'7%'} align="left">
                      <span className={classes.tableText}>
                        {TransitionOwnershipType[row.ownershipStatus!] || '-'}
                      </span>
                    </TableCell>
                    <TableCell width={'6%'} align="left">
                      <span className={classes.tableText}>
                        {row.transitionTeamMembersCount || 0}
                      </span>
                    </TableCell>
                    {canEdit && <TableCell align="right" width={'4%'} style={{ paddingRight: 16 }}>
                      <TransitionMenu projectId={project!.projectId} singleTransition={row} isActive={row.isActive}
                      />
                    </TableCell>}
                  </TableRow>
                  {row.transitionComponent === TransitionComponent.Aircraft && <TableRow
                    key={row.transitionId + 'subassets'}>
                    <TableCell colSpan={15}>
                      <Accordion expanded={expanded === row.transitionId + 'collapse'} onChange={handleChange(row.transitionId + 'collapse')}>
                        <AccordionSummary
                          style={{ borderBottom: 0 }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${row.transitionId + 'collapse'}bh-content`}
                          id={`${row.transitionId + 'collapse'}bh-header`}
                        >
                          <Typography variant='body2' >Sub-Assets</Typography>
                        </AccordionSummary>
                        {row.subAssets !== undefined && row.subAssets !== null && <AccordionDetails>
                          <Grid container direction={'column'}>
                            <TableData
                              noShadow
                              noGrow={true}
                              tinyHeading={true}
                              loading={isLoading}
                              loadingText="Loading Assets"
                              noResultsProps={{
                                type: 'transitions',
                                message: 'No assets'
                              }}
                              hideHeader={true}
                              headCells={[]}
                              tableRows={
                                row.subAssets.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                  .map((rowSubasset) => {
                                    return (
                                      <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={rowSubasset.transitionId}
                                        className={classes.bodyRowsSubassets}>
                                        <TableCell
                                          id={rowSubasset.userId}
                                          width={'15%'}
                                          align={'left'}
                                          style={{ paddingLeft: 16 }}>
                                          <div className={classes.transitionName}
                                            onClick={() => rowSubasset.isActive ? openTransition(rowSubasset.transitionId!) : null}>
                                            {rowSubasset.transitionComponent === TransitionComponent.Engine && <span className={classes.engine}>ENG&nbsp;&nbsp;</span>}
                                            {rowSubasset.transitionComponent === TransitionComponent.LandingGear && <span className={classes.landingGear}>LDG&nbsp;&nbsp;</span>}
                                            {rowSubasset.transitionComponent === TransitionComponent.APU && <span className={classes.apu}>APU&nbsp;&nbsp;</span>}
                                            {rowSubasset.transitionComponent === TransitionComponent.RAT && <span className={classes.rat}>RAT&nbsp;&nbsp;</span>}
                                            {rowSubasset.transitionComponent === TransitionComponent.THSActuator && <span className={classes.ths}>THS&nbsp;&nbsp;</span>}
                                            <span>{rowSubasset.name}</span>
                                          </div>
                                        </TableCell>
                                        <TableCell width={'6%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.registration || '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>
                                            {TransitionComponent[rowSubasset.transitionComponent!] || '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'8%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.transitionComponent === TransitionComponent.Engine && (rowSubasset.engineManufacturer !== undefined ? EngineManufacturer[rowSubasset.engineManufacturer!] : '-')}
                                            {rowSubasset.transitionComponent !== TransitionComponent.Engine && (rowSubasset.manufacturerName || '-')}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.transitionComponent === TransitionComponent.Engine
                                              ? (EngineManufacturerType[rowSubasset.engineManufacturerType!] || '-')
                                              : '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>-</span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.transitionComponent === TransitionComponent.Engine
                                              ? (EngineManufacturerTypeVariant[rowSubasset.engineManufacturerTypeVariant!] || '-')
                                              : '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.transitionComponent === TransitionComponent.Engine ? (FamilyType[rowSubasset.familyType!] || '-') : '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'6%'} align="left">
                                          <span className={classes.tableText}>
                                            {SubassetPosition[rowSubasset.installedPosition!] || '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'6%'} align="left">
                                          <span className={classes.tableText}>
                                            {AssetStatus[rowSubasset.status!]}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.owner || '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'7%'} align="left">
                                          <span className={classes.tableText}>
                                            {TransitionOwnershipType[rowSubasset.ownershipStatus!] || '-'}
                                          </span>
                                        </TableCell>
                                        <TableCell width={'6%'} align="left">
                                          <span className={classes.tableText}>
                                            {rowSubasset.transitionTeamMembersCount || 0}
                                          </span>
                                        </TableCell>
                                        {canEdit && <TableCell align="right" width={'4%'} style={{ paddingRight: 16 }}>
                                          <TransitionMenu projectId={project!.projectId} singleTransition={rowSubasset} isActive={rowSubasset.isActive!}
                                          />
                                        </TableCell>}
                                      </TableRow>
                                    )
                                  })
                              }>
                            </TableData>
                          </Grid>
                        </AccordionDetails>
                        }
                      </Accordion>
                    </TableCell>
                  </TableRow>}
                </Fragment>
              )
            })}>
      </TableData>
      <div className={classes.paginationContainer}>
        <WithPagination
          page={page}
          pages={pages}
          setPage={(value: number) => setPage(value)}>
        </WithPagination>
      </div>
    </Grid>
  )
}
