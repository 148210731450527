import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Grid,
  makeStyles
} from '@material-ui/core'
import { closeModal } from 'redux/slices/appSlice'
import { Manufacturer, Transition, TransitionComponent } from 'types'
import { useLisaForm } from 'common/Form/LisaForm'
import { TransitionInfoForm } from 'pages/Administration/TransitionForm/TransitionInfoForm'
import { hasAllowedCharacters, INVALID_CHARACTERS } from 'utils/validators'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    position: 'relative',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  actionButtons: {
    padding: '32px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

type FormValues = Partial<Transition>

type FormErrors = {
  name?: string
  transitionType?: string
  transitionComponent?: string
  manufacturer?: string
  aircraftType?: string
  msn?: string
  registration?: string
  apuPn?: string
  landingGears?: string
  manufacturerName?: string
  modelName?: string
}

const initialValues: FormValues = {
  transitionComponent: TransitionComponent.Aircraft,
  name: '',
  transitionType: 1,
  manufacturer: 1,
  aircraftType: 1,
  aircraftSubtype: 1,
  variation: 1,
  description: '',
  subAssets: []
}

type TransitionFormProps = {
  transition?: Transition,
  isLoading: boolean,
  canCancel?: boolean,
  onSave: (values: FormValues) => Promise<{ isError: boolean, message: string }>,
  showSubassetTeam?: boolean
}

export const TransitionForm: FC<TransitionFormProps> = ({ isLoading, transition, onSave, canCancel = false, showSubassetTeam = true }) => {
  const [_initialValues] = useState<FormValues>(transition ?? initialValues)

  const dispatch = useDispatch()
  const classes = useStyles()
  const [transitionError, setTransitionError] = useState(false)
  const [transitionErrorMessage, setTransitionErrorMessage] = useState('')

  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = [
      'name', 'msn'
    ]

    if (values.manufacturer === Manufacturer.Other) {
      validationFields.push('manufacturerName')
    }

    const error = validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        } else if (field === 'name' && !hasAllowedCharacters(values.name ?? '')) {
          e.name = `Name cannot contain ${INVALID_CHARACTERS}`
          isValid = false
        }

        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
    return error
  }, [])

  const {
    values,
    errors,
    checkForErrors,
    setValue: setTransitionValue
  } = useLisaForm<FormValues, FormErrors>(
    { ..._initialValues },
    validate,
    false
  )

  const handleClose = () => {
    dispatch(closeModal(['TRANSITION_FORM', 'TRANSITION_FORM_PROJECTS', 'TRANSITION_FORM_MENU']))
  }

  const subassetsChanged = (subassets: Partial<Transition>[]) => {
    values.subAssets = subassets
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <TransitionInfoForm
            projectId={transition!.projectId}
            transition={values}
            errors={errors ?? {}}
            transitionError={transitionError}
            transitionErrorMessage={transitionErrorMessage}
            onChange={setTransitionValue}
            onSubassetsChange={subassetsChanged}
            showSubassetTeam={showSubassetTeam}/>
        </Grid>
        <div className={classes.actionButtons}>
          <Button
            onClick={async () => {
              const isValid = checkForErrors()

              if (isValid) {
                await onSave(values)
              }
            }
            }
            variant='contained'
            color='primary'
            size='small'
            disabled={isLoading}
          >
            Save
          </Button>
          {
            canCancel &&
            <Button
              onClick={handleClose}
              variant='contained'
              color='primary'
              size='small'
              disabled={isLoading}>
              Cancel
            </Button>
          }
        </div>
      </div>
    </>
  )
}
