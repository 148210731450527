import { ChangeEvent, FC, Fragment, MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import LisaLoader from 'common/Loaders/LisaLoader'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { FamilyType, Manufacturer, Nullable, ProjectId, Transition, TransitionComponent, UserListRow } from 'types'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useLisaForm } from 'common/Form/LisaForm'
import { Alert } from '@material-ui/lab'
import { TransitionInfoForm } from 'pages/Administration/TransitionForm/TransitionInfoForm'
import { hasAllowedCharacters, INVALID_CHARACTERS } from 'utils/validators'
import { useGetUsersQuery } from 'services/api/usersApi'
import { useCreateOrEditTransitionMutation, useGetTransitionTeamQuery } from 'services/api/transitionApi'
import ComboBox from 'common/LisaControls/ComboBox'
import Prompt from 'common/Dialogs/Prompt'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    position: 'relative',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    },
    '& .MuiAppBar-colorDefault': {
      background: theme.palette.common.white
    },
    '& .tabs': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '16px'
    }
  },
  controlsWrapper: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
    padding: '0px !important'
  },
  actionButtons: {
    padding: '32px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  appBar: {
    background: 'transparent',
    width: 'auto',
    '& .MuiTabs-root .MuiTab-textColorPrimary.Mui-selected': {
      borderBottom: `6px solid ${theme.palette.blue.main}`
    },
    '& .MuiTab-textColorPrimary.Mui-disabled > .MuiTab-wrapper': {
      color: theme.palette.black.light100
    },
    '& .MuiTabs-root .MuiTab-wrapper': {
      color: theme.palette.blue.main
    }
  },
  searchSpiner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCircularProgress-colorPrimary': {
      color: '#001E50 !important'
    }
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1101
  },
  userName: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 600
  },
  userRole: {
    marginBottom: '4px',
    '& .MuiAlert-message': {
      padding: '0',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.black.main
    },
    '& .MuiAlert-root': {
      padding: '5px 16px'
    },
    '& .MuiAlert-standardInfo': {
      background: 'white',
      '&:hover': {
        background: theme.palette.grey2.light
      }
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.black.main
    }
  },
  userList: {
    paddingBottom: '8px'
  },
  boxPadding: {
    padding: '0px'
  },
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    paddingLeft: '16px',
    borderRadius: '4px 4px 0 0',
    backgroundColor: `${theme.palette.grey2.main}`
  }
}))

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
  boxPadding?: boolean
}

function TabPanel (props: TabPanelProps) {
  const { children, value, index, boxPadding, ...other } = props
  const classes = useStyles()

  return (
    <div
      style={{ marginTop: '16px' }}
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} className={boxPadding ? classes.boxPadding : ''}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps (index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

type TransitionUsersListProps = {
  user?: string,
  remove: (val: string) => void
}

export const TransitionUsersList: FC<TransitionUsersListProps> = ({ user, remove }) => {
  const { data: allUsers = [] } = useGetUsersQuery()
  const classes = useStyles()
  const tooltipWrapper = (user: string) => {
    const result: UserListRow[] = allUsers.filter((item) => item.userId === user)
    if (!result[0]) {
      return null
    }

    return (
      <Tooltip
        placement='right-start'
        title={
          <div>
            {result[0].roles.map((role, i) => (
              <div key={role.roleId}>
                {`${role.name}`}{result[0].roles.length === i + 1 ? '' : ','}
              </div>
            ))}
          </div>
        }
      >
        <div>
          <div className={classes.userName}>{`${result[0].firstName} ${result[0].lastName}`}</div>
          <Typography variant='caption'>{result[0].email}</Typography>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={classes.userRole}>
      <Collapse in={true}>
        <Alert
          icon={false}
          severity='info'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={
                () => remove(user!)
              }
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {tooltipWrapper(user!)}
        </Alert>
      </Collapse>
    </div>
  )
}

type FormValues = Partial<Transition>

type FormErrors = {
  name?: string
  transitionType?: string
  transitionComponent?: string
  manufacturer?: string
  aircraftType?: string
  msn?: string
  registration?: string
  apuPn?: string
  landingGears?: string
  manufacturerName?: string
  modelName?: string
}

const initialValues: FormValues = {
  transitionComponent: TransitionComponent.Aircraft,
  name: '',
  msn: '',
  transitionType: 1,
  manufacturer: 1,
  aircraftType: 1,
  aircraftSubtype: 1,
  familyType: FamilyType.NB,
  variation: 1,
  description: '',
  subAssets: [],
  status: 6,
  ownershipStatus: 1,
  owner: '',
  previousOperator: '',
  newOperator: '',
  weightVariant: '',
  weightVariantGroup: '',
  mtow: ''
}

type TransitionFormProps = {
  projectId: ProjectId
  transition?: Transition,
  fromView?: boolean
}

export interface UsersListOption {
  value: string
  name: string
  email?: string
}

export interface FinalUsers {
  value: string
  name: string
  email?: string
}

export const TransitionForm: FC<TransitionFormProps> = ({ projectId, transition, fromView }) => {
  const [value, setValue] = useState(0)
  const [_initialValues] = useState<FormValues>(transition ?? initialValues)
  const [newUserList, setNewUserList] = useState<string[]>([])
  const [valuesDifference, setValuesDifference] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UsersListOption>({ value: '', name: 'Select', email: '' })
  const [inputValue, setInputValue] = useState<string>('')
  const dispatch = useDispatch()
  const classes = useStyles()
  const { userId, currentTenant } = useLisaAuth()
  const isEdit = transition !== undefined // @todo
  const transitionId = transition?.transitionId ?? ''
  const { data: team = [] } = useGetTransitionTeamQuery(transitionId, { skip: !isEdit, refetchOnMountOrArgChange: true })
  const { data: allUsers = [] } = useGetUsersQuery()
  const [createOrEditTransition, { isLoading: transLoader, data }] = useCreateOrEditTransitionMutation()
  const transitionError = data?.isError ?? false
  const transitionErrorMessage = data?.message ?? ''

  useEffect(() => {
    isEdit && team && setNewUserList(team.map(_ => _.userId))
  }, [team])

  const finalUserList: FinalUsers[] = allUsers.map(_ => ({
    value: _.userId,
    name: `${_.firstName} ${_.lastName}`,
    email: _.email
  }))

  const emptyUserList = { value: '', name: 'Select', email: '' }
  const dropdownUserList: UsersListOption[] = [emptyUserList, ...finalUserList]

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    event.stopPropagation()
    setValue(newValue)
  }

  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = [
      'name', 'msn'
    ]

    if (values.manufacturer === Manufacturer.Other) {
      validationFields.push('manufacturerName')
    }

    const error = validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        } else if (field === 'name' && !hasAllowedCharacters(values.name ?? '')) {
          e.name = `Name cannot contain ${INVALID_CHARACTERS}`
          isValid = false
        }

        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
    if (!error.isValid) {
      setValue(0)
    }
    return error
  }, [])

  const {
    values,
    errors,
    checkForErrors,
    setValue: setTransitionValue
  } = useLisaForm<FormValues, FormErrors>(
    { ..._initialValues },
    validate,
    false
  )

  const handleClose = () => {
    dispatch(closeModal(['TRANSITION_FORM', 'TRANSITION_FORM_PROJECTS', 'TRANSITION_FORM_MENU']))
  }

  const addUserToList = (item: string) => {
    if (!newUserList.includes(item)) {
      setNewUserList([...newUserList, item])
    }
  }

  const remove = (removeItem: string) => {
    setNewUserList(newUserList.filter((item) => item !== removeItem))
  }

  const createNewTransition = async (e: MouseEvent) => {
    e.preventDefault()

    const isValid = checkForErrors()
    if (isValid) {
      const temp = {
        ...values,
        name: values.name?.trim(),
        userId: userId!,
        users: newUserList,
        transitionId: transition?.transitionId,
        projectId,
        isActive: true,
        tenantId: currentTenant
      }
      const { isError, message } = await createOrEditTransition(temp).unwrap()
      if (!isError) {
        dispatch(openToast({
          severity: 'success',
          message: isEdit ? 'You have successfully updated an asset.' : 'You successfully created new asset.'
        }))
        handleClose()
        setValuesDifference(false)
      }
      if (isError && message !== null) {
        dispatch(openToast({ severity: 'error', message: message }))
      }
    }
  }

  const handleUserInputChange = (item: UsersListOption) => {
    setSelectedUser(item)
  }
  const subassetsChanged = (subassets: Partial<Transition>[]) => {
    values.subAssets = subassets
  }

  useEffect(() => {
    if (isEdit) {
      setValuesDifference(JSON.stringify(transition) !== JSON.stringify(values))
    }
  }, [values])

  useEffect(() => {
    values.name = 'MSN ' + values.msn
  }, [values.msn, values.aircraftType])

  return (
    <>
      <Prompt when={valuesDifference} pageName={'asset'} />
      {transLoader && <div className={classes.overlay}/>}
      <div className={classes.root}>
        {!fromView
          ? <><div className={'tabs'}>
            <AppBar position='static' elevation={0} className={classes.appBar}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
                aria-label='full width tabs example'
              >
                <Tab label='Info' {...a11yProps(0)} />
                <Tab label='Team' {...a11yProps(2)} />
              </Tabs>
            </AppBar>
          </div>

          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <TransitionInfoForm
                projectId={projectId}
                transition={values}
                errors={errors ?? {}}
                transitionError={!!transitionError}
                transitionErrorMessage={transitionErrorMessage}
                onChange={setTransitionValue}
                onSubassetsChange={subassetsChanged}/>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} boxPadding>
            <Grid container>
              <Grid item xs={12} className={classes.userList}>
                {newUserList &&
              newUserList.map((user, i) => (
                <TransitionUsersList
                  key={i}
                  user={user}
                  remove={(item: string) => remove(item)}
                />
              ))}
              </Grid>
              <Grid item xs={12} className={classes.controlsWrapper} >
                <Grid item xs={6}>
                  <ComboBox
                    label={'Select user'}
                    options={dropdownUserList}
                    getOptionLabel={(mtu: UsersListOption) => mtu.name ?? ''}
                    onInputChange={(e, newInputValue: string) => {
                      setInputValue(newInputValue)
                    }}
                    onChange={(e: ChangeEvent<{}>, newValue: Nullable<UsersListOption>) => {
                      newValue && handleUserInputChange(newValue)
                    }}
                    value={selectedUser}
                    renderOption={(option: UsersListOption) => (
                      <Fragment>
                        <div>
                          <div className={classes.userName}>{option.name}</div>
                          <Typography variant='caption'>{option.email}</Typography>
                        </div>
                      </Fragment>
                    )}
                    getOptionSelected={(opt, { value }) => opt.value === value}/>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    style={{ margin: '18px 0 0 8px' }}
                    disabled={(selectedUser && selectedUser.value === '') || inputValue.length === 0}
                    onClick={() => addUserToList(selectedUser.value!)}
                  >
                    ADD
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          </>
          : <Grid container spacing={0}>
            <TransitionInfoForm
              projectId={projectId}
              transition={values}
              errors={errors ?? {}}
              transitionError={!!transitionError}
              transitionErrorMessage={transitionErrorMessage}
              onChange={setTransitionValue}
              onSubassetsChange={subassetsChanged}/>
          </Grid>
        }
        <div className={classes.actionButtons}>
          <Button
            onClick={createNewTransition}
            variant='contained'
            color='primary'
            size='small'
            disabled={transLoader}
          >
           Save
          </Button>
          {!fromView && <Button
            onClick={handleClose}
            variant='contained'
            color='primary'
            size='small'
            disabled={transLoader}
          >
          Cancel
          </Button>}
        </div>
        {transLoader &&
            <div className={classes.searchSpiner}>
              <LisaLoader text={
                isEdit
                  ? 'Editing existing asset'
                  : 'Creating a new asset...'} />
            </div>
        }
      </div>
    </>
  )
}
